export interface RecCatalogDto {
  data: RecContentItemDto[];
  included: RecTaxonomyNodeDto[];
}

export interface RecJsonApiDataItem {
  id: string;
  type: string;
}

export interface RecJsonApiData {
  links?: {};
  data: RecJsonApiDataItem[];
  meta?: {};
}

export interface RecTaxonomyNodeRelationshipsDto {
  children: RecJsonApiData;
}

export enum RecTaxonomyNodeAttributesNodeTypeDto {
  CHAPTER = 'CHAPTER',
  BOOK = 'BOOK'
}

export interface RecTaxonomyNodeAttributesDto {
  id: string;
  vtwId: string;
  level: number;
  displayName: string;
  displayOrder: number;
  text: string;
  leaf: boolean;
  root: boolean;
  nodeType: RecTaxonomyNodeAttributesNodeTypeDto;

}

export interface RecTaxonomyNodeDto {
  id: string;
  type: string;
  attributes: Partial<RecTaxonomyNodeAttributesDto>;
  relationships: Partial<RecTaxonomyNodeRelationshipsDto>;
}

export interface RecContentItemTaxonomyNodeDto {
  id: string;
  type: string;
}

export interface RecContentItemAttributesDto {
  contentId: string;
  isbn: string;
  displayName: string;
  isPublished: boolean;
  publishDate: string; // 2022-05-22T00:00:00.000UTC
  // isStudent: boolean; // This does not exist in API yet need to implement this with ECM team
}

export interface RecContentItemRelationshipsDto {
  taxonomies: RecJsonApiData;
}

export interface RecContentItemDto {
  type: RecContentItemTypeDto;
  id: string;
  attributes: Partial<RecContentItemAttributesDto>;
  relationships: Partial<RecContentItemRelationshipsDto>;
}

export enum RecContentItemTypeDto {
  ADAPTIVE_QUIZ = 'ADAPTIVE_QUIZ',
  ADAPTIVE_LESSON = 'ADAPTIVE_LESSON',
  EBOOK_READING = 'EBOOK_READING',
  EVOLVE_RESOURCE = 'EVOLVE_RESOURCE',
  SHERPATH_LESSON = 'SHERPATH_LESSON',
  SHERPATH_SKILL = 'SHERPATH_SKILL',
  SHERPATH_SIMULATION = 'SHERPATH_SIMULATION',
  SIM_CHART = 'SIM_CHART',
  QUESTION = 'QUESTION', // This is for EAQ and AL questions and should never be represented on the course plan as an item
  HESI_EXAM = 'HESI_EXAM',
  SHADOW_HEALTH = 'SHADOW_HEALTH',
  SHERPATH_MODULE = 'SHERPATH_MODULE',
  SHERPATH_POWERPOINT = 'SHERPATH_POWERPOINT',
  SHERPATH_GROUP_ACTIVITY = 'SHERPATH_GROUP_ACTIVITY',
  SHERPATH_CASE_STUDY = 'SHERPATH_CASE_STUDY',
  OSMOSIS_VIDEO = 'OSMOSIS_VIDEO',
  SHERPATH_TESTBANK = 'SHERPATH_TESTBANK',
  REVIEW_QUIZ = 'REVIEW_QUIZ',
}

export const CoursewareSupportedRecContentItems = [
  RecContentItemTypeDto.ADAPTIVE_LESSON,
  RecContentItemTypeDto.EVOLVE_RESOURCE,
  RecContentItemTypeDto.SHERPATH_LESSON,
  RecContentItemTypeDto.SHERPATH_SIMULATION,
  RecContentItemTypeDto.SHERPATH_SKILL,
  RecContentItemTypeDto.SIM_CHART,
  RecContentItemTypeDto.EBOOK_READING,
  RecContentItemTypeDto.HESI_EXAM,
  RecContentItemTypeDto.SHADOW_HEALTH,
  RecContentItemTypeDto.SHERPATH_MODULE,
  RecContentItemTypeDto.SHERPATH_POWERPOINT,
  RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY,
  RecContentItemTypeDto.SHERPATH_CASE_STUDY,
  RecContentItemTypeDto.OSMOSIS_VIDEO,
  RecContentItemTypeDto.SHERPATH_TESTBANK,
];
