import { AssignmentManagementAppId } from './app.config';

export enum RoutePath {
  ADMIN = '/super-secret-admin-page',
  APP_LINK_REDIRECT = '/redirect',
  CATALOG = '/catalog',
  SHERPATH_LESSON_EDITOR = '/sherpath-lesson-editor',
  CASE_STUDY = '/case-study',
  GROUP_ACTIVITY = '/group-activty',
  COURSE = '/course-plan',
  COURSE_BUILDER = '/course-builder',
  CW_LOGOUT = '/cwlogout',
  TIMEOUT = '/timedout',
  EBOOK_ASSIGNMENT_EDITOR = '/ebook-assignment-editor',
  EBOOKS = '/ebooks',
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SETTINGS = '/settings',
  SKILL_ASSIGNMENT_PLAYER = '/skill-assignment-player',
  SKILL_ASSIGNMENT_EDITOR = '/skill-assignment-editor',
  INTERACTIVE_REVIEW_ASSIGNMENT_EDITOR = '/interactive-review-assignment-editor',
  INTERACTIVE_REVIEW_ASSIGNMENT_PREVIEW = '/interactive-review-assignment-preview',
  EAQ_ASSIGNMENT_EDITOR = '/eaq-assignment-editor',
  EAQ_MASTERY_RESET = '/eaq-mastery-reset',
  SIMULATION_ASSIGNMENT_EDITOR = '/simulation-assignment-editor',
  SIMULATION_ASSIGNMENT_PLAYER = '/simulation-assignment-player',
  OSMOSIS_ASSIGNMENT_PLAYER = '/osmosis-assignment-player',
  SHERPATH_POWERPOINT_ASSIGNMENT_EDITOR = '/sherpath-powerpoint-assignment-editor',
  SHERPATH_GROUP_ACTIVITY_ASSIGNMENT_EDITOR = '/sherpath-group-activity-assignment-editor',
  SHERPATH_CASE_STUDY_ASSIGNMENT_EDITOR = '/sherpath-case-study-assignment-editor',
  EVOLVE_RESOURCE_ASSIGNMENT_EDITOR = '/evolve-resource-assignment-editor',
  EXTERNAL_LINK_ASSIGNMENT_EDITOR = '/external-link-assignment-editor',
  OSMOSIS_VIDEO_EDITOR = '/osmosis-video-editor',
  ASSIGNMENT_PERFORMANCE_REPORT = '/assignment-performance-report',
  SKILL_ATTEMPT_RESULT = '/skill-attempt-result',
  COURSE_SWITCHER = '/course-switcher',
  SHADOW_HEALTH_ASSIGNMENT_EDITOR = '/shadow-health-assignment-editor',
  BULK_EDIT_SETTINGS = '/bulk-edit-settings',
  SUPPORT = '/support',
  HELP = '/help',
  ASSIGNMENT_DETAIL_REPORT = '/assignment-detail-report'
}

export const ServicePath = {
  loginPath: '/login-service/login',
  tokenServicePath: '/token-service',
  userServicePath: '/apiv1/eolsUser/list',
};

export const AppConstants = {
  APP_ID: AssignmentManagementAppId,
  IDLE_TIMEOUT: 1000 * 60 * 60 * 6,
  PENDO_API_KEY: '94bb3f52-df30-4f69-4ec8-dceb6bffffbe'
};
