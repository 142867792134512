import {
  reduce,
  isEmpty,
  keyBy,
  isNil,
  get
} from 'lodash';
import { createSelector } from 'reselect';
import {
  ELSTokenHelper,
  ELSCommonConfig
} from '@els/els-ui-common-react';
import {
  AdobeAnalyticsPageData,
  CoursewareStore
} from './courseware.models';
import { reduxStateDomains } from '../redux.constants';
import {
  filterNotFoundError,
  includesConflictError,
  getEvolveResourcesFromCourseSection,
  getNormalizedInstitution
} from '../redux.utilities';
import {
  FALSE_VALUE,
  FEATURE_FLAG,
  TRUE_VALUE
} from '../../apis/eols-features-api/eols-features-api.constants';
import {
  AssessmentDto,
  AssessmentStatusDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  ExternalEntityDto,
  OsmosisTokenDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { AppLinkData } from '../../apis/eols-app-link/eols-app-link.dtos';
import {
  ActiveSyllabusItemTypeDto,
  ExternalIdTypeDto
} from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { ELSCourseSectionDto } from '../../models/els.dtos';
import { getAssociatedAssignment } from '../../pages/course-plan/syllabus.utilities';
import { ResourceStatusMap } from '../../pages/catalog/catalog.models';
import { getSortedSyllabusTreeMapItems } from '../../pages/course-builder/courseBuilder.utilities';
import { CrosswalkUserDto } from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import {
  getContentItemIdFromAssignment,
  getSortedSyllabusFolderOrderMap,
} from '../../pages/catalog/catalog.utilities';
import {
  getABPropsForAnalytics,
  getBooleanFromGroupFeatureFlagWithFallbackToGlobal,
} from '../../utilities/featureFlag.utilities';
import {
  SequenceMap,
  SkillQuestionStaticData,
  SkillSubmissionData
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { scrubProps } from '../../utilities/analytics.utilities';
import { locationSelectors } from '../location/location.selectors';
import { EolsUserEngagementDto } from '../../apis/eols-user-engagement/eols-user-engagement.dtos';
import { UNKNOWN } from './courseware.constants';
import {
  getEmptyPageData,
  getSemester
} from '../../utilities/adobe-analytics-utilities';
import {
  isInstructor,
  isStudent
} from '../../utilities/common.utilities';
import { convertIsbnsToNormalizedString } from '../../utilities/app.utilities';
import { MigratedCourseEntitlementDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.utilities';
import { FeatureFlagDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { Application } from '../../apis/eols-app-link/eols-app-link.constants';
import { RecContentItemDto } from '../../apis/rec-gateway/rec-gateway.dtos';

const getCwStore = (globalState): CoursewareStore => {
  return globalState[reduxStateDomains.COURSEWARE_STATE];
};

const getCourseSectionId = globalState => getCwStore(globalState).courseSectionId;

const getRoleId = globalState => getCwStore(globalState).roleId;

const getUserId = globalState => getCwStore(globalState).userId;

const getRegisteredToken = globalState => getCwStore(globalState).registeredToken;

const getIsLoading = globalState => {
  return getCwStore(globalState).pendingRequestCount > 0;
};

const getCourseName = globalState => {
  const appStore = getCwStore(globalState);
  return get(appStore, 'currentCourse.courseName', '');
};

const getIsCourseLocked = globalState => {
  const appStore = getCwStore(globalState);
  return get(appStore, 'currentCourse.locked', false);
};

const getCourse = globalState => getCwStore(globalState).currentCourse;

const getUsers = globalState => getCwStore(globalState).users;

const getEaqUsers = globalState => getCwStore(globalState).eaqUsers;

const getEaqIsbns = globalState => getCwStore(globalState).eaqIsbns;

const getMasteryResetSuccess = globalState => getCwStore(globalState).masteryResetSuccess;

const getRawIsbns = globalState => getCwStore(globalState).isbns;
const getIsbns = createSelector(
  getRawIsbns,
  (isbns) => {
    if (!isbns) {
      return null;
    }
    return isbns.split(',');
  }
);

const getEvolveProducts = createSelector(
  [getIsbns, getCourse],
  getEvolveResourcesFromCourseSection
);

const getUser = globalState => {
  const {
    userId,
    users
  } = getCwStore(globalState);

  if (!users) {
    return null;
  }

  return users.find(x => x.id === parseInt(userId, 10));
};

const getUserDisplayName = createSelector(
  getUser,
  (user) => {
    if (!user) {
      return null;
    }
    return `${user.firstName}`;
  }
);

const getUserHistory = globalState => {
  return getCwStore(globalState).userHistory;
};

const getUserHistoryStateCompletedRequests = globalState => {
  return getCwStore(globalState).userHistoryStateCompletedRequests;
};

const getCurrentUserCourseOwnerRecords = globalState => {
  return getCwStore(globalState).userCourseOwnerRecords;
};

const getSyllabusFolderInfo = (globalState) => {
  return getCwStore(globalState).syllabusFolderInfo;
};

const getCurrentUserCourseOwnerRecord = createSelector(
  getCurrentUserCourseOwnerRecords,
  (userCourseOwnerRecords) => {
    if (!userCourseOwnerRecords || !userCourseOwnerRecords.length) {
      return null;
    }

    return userCourseOwnerRecords[0];
  }
);

const getIsCourseOwner = createSelector(
  getCurrentUserCourseOwnerRecord,
  (matchingUserRecord) => {
    if (!matchingUserRecord) {
      return false;
    }

    return matchingUserRecord.stateInfo === TRUE_VALUE;
  }
);

const getErrors = globalState => {
  const cwStore = globalState[reduxStateDomains.COURSEWARE_STATE] as CoursewareStore;

  return cwStore.errors;
};

const getToastableErrors = globalState => {
  const errors = getErrors(globalState);
  return filterNotFoundError(errors);
};

const getConflictErrors = globalState => {
  const errors = getErrors(globalState);
  return includesConflictError(errors);
};

const getInstitution = globalState => {
  return getCwStore(globalState).currentCourse && getCwStore(globalState).currentCourse.institution;
};

const getMessages = globalState => getCwStore(globalState).messages;

const getIsLoadingLanguage = globalState => isEmpty(getCwStore(globalState).messages);

const getFeatureFlagsGrouped = globalState => getCwStore(globalState).featureFlagsGrouped;

const getIsMaintenance = createSelector(
  [getFeatureFlagsGrouped, getCourseSectionId],
  (flags, courseSectionId) => {
    const value = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(flags, FEATURE_FLAG.IS_SITE_DOWN_FOR_MAINTENANCE, courseSectionId);
    return value === null ? false : value;
  }
);

const getCatalog = (globalState) => getCwStore(globalState).catalog;

const getPrimaryTaxonomies = (globalState) => getCwStore(globalState).primaryTaxonomies;
const getTaxonomies = (globalState) => getCwStore(globalState).taxonomies;

const getAllSyllabusItems = globalState => getCwStore(globalState).syllabusItems;
const getSyllabusItems = createSelector(
  getAllSyllabusItems,
  (syllabusItems) => {
    return syllabusItems.filter(item => !item.isDeleted);
  }
);
const getAssignments = (globalState): AssignmentDto[] => getCwStore(globalState).assignments;
const getAssignmentsDictionary = createSelector(
  getAssignments,
  (assignments) => {
    return keyBy(assignments, 'id');
  }
);
const getExternalEntities = (globalState): ExternalEntityDto[] => getCwStore(globalState).externalEntities;
const getSyllabusFolders = globalState => {
  return getCwStore(globalState).syllabusItems.filter(item => item.type === ActiveSyllabusItemTypeDto.FOLDER);
};

const getIsBatchEditModeEnabled = (globalState) => {
  return getCwStore(globalState).isBatchEditModeEnabled;
};

const getIsDragDropModeEnabled = (globalState) => {
  return getIsCourseLocked(globalState) ? false : getCwStore(globalState).isDragDropModeEnabled;
};

const getCheckedSyllabusItemIds = (globalState) => {
  return getCwStore(globalState).checkedSyllabusItemIds;
};

const getCourseBuilderState = (globalState) => {
  return getCwStore(globalState).courseBuilderState;
};

const getAssessmentSubmissionsMap = (globalState) => {
  return getCwStore(globalState).assessmentSubmissionsMap;
};

const getGroupActivity = (globalState) => {
  return getCwStore(globalState).groupActivity;
};

const getEolsUserCourseSections = (globalState): ELSCourseSectionDto[] => {
  return get(getCwStore(globalState), 'eolsUser.courseSections', []);
};

const getEolsCrosswalkUserCourseSections = (globalState): ELSCourseSectionDto[] => {
  const { crosswalkUsers = [] } = getCwStore(globalState);
  return crosswalkUsers.map(user => user.courseSections).flat();
};

const getResourceStatusMap = createSelector(
  [getSyllabusItems, getAssignments, getIsLoading],
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (syllabusItems, assignments, isLoading) => {
    if (isLoading || syllabusItems.length === 0) {
      return {};
    }

    const sortedSyllabusTreeMapItems = getSortedSyllabusTreeMapItems(syllabusItems);
    const sortedSyllabusFolderMap = getSortedSyllabusFolderOrderMap(sortedSyllabusTreeMapItems);
    const resourceStatusMap = syllabusItems.reduce<ResourceStatusMap>((acc, cur) => {
      const { parentId, type } = cur;
      const catalogExId = cur.externalIdentifiers.find(exId => exId.type === ExternalIdTypeDto.CATALOG_ITEM_ID);
      const assignmentId = cur.externalIdentifiers.find(exId => exId.type === ExternalIdTypeDto.ASSIGNMENT_ID);
      if (!parentId || type === ActiveSyllabusItemTypeDto.EBOOK_READING || (!catalogExId && !assignmentId)) {
        return acc;
      }
      const parentItem = syllabusItems.find(item => item.id === parentId);
      const associatedAssignment = getAssociatedAssignment(cur, assignments);
      const isVisible = associatedAssignment && !!associatedAssignment.availableDate;
      const isAssigned = isVisible && !!associatedAssignment.dueDate;

      if (!associatedAssignment && !catalogExId) {
        return acc;
      }

      const contentItemId = associatedAssignment ? getContentItemIdFromAssignment(associatedAssignment) : catalogExId.value;

      let status = 'added';
      if (isAssigned) {
        status = 'assigned';
      } else if (isVisible) {
        status = 'visible';
      }
      if (acc[contentItemId]) {
        return {
          ...acc,
          [contentItemId]: {
            ...acc[contentItemId],
            [status]: acc[contentItemId][status].concat([{
              syllabusItem: cur,
              parentItem
            }]),
          }
        };
      }
      return {
        ...acc,
        [contentItemId]: {
          visible: [],
          assigned: [],
          added: [],
          [status]: [{
            syllabusItem: cur,
            parentItem,
          }],
        },
      };
    }, {});

    const sortFn = (a, b) => {
      if (!a.parentItem || !b.parentItem) {
        return 0;
      }
      if (!sortedSyllabusFolderMap[a.parentItem.id] || !sortedSyllabusFolderMap[b.parentItem.id]) {
        return 0;
      }
      return sortedSyllabusFolderMap[a.parentItem.id].order - sortedSyllabusFolderMap[b.parentItem.id].order;
    };

    return reduce(resourceStatusMap, (acc, mapItem, key) => {
      return {
        ...acc,
        [key]: {
          added: mapItem.added.sort(sortFn),
          visible: mapItem.visible.sort(sortFn),
          assigned: mapItem.assigned.sort(sortFn),
        }
      };
    }, {});
  }
);

const getEvolveUser = (globalState): CrosswalkUserDto => {
  return getCwStore(globalState).evolveUser;
};

const getAssessmentStartTimeMap = (globalState): Record<string, Date> => {
  return getCwStore(globalState).assessmentStartTimeMap;
};

const getAppLinkCookies = (globalState): {
  token: string;
  linkId: string;
} => {
  return getCwStore(globalState).appLinkCookies;
};

const getLinkData = (globalState): AppLinkData => {
  return getCwStore(globalState).linkData;
};

const getCollapsedFolderIds = (globalState): string[] => {
  return getCwStore(globalState).collapsedFolderIds;
};

const getSkillSubmissionData = (globalState): {
  submissionData: SkillSubmissionData[];
  vtwId: string;
  skillStaticData: SkillQuestionStaticData[];
  skillVersionVtwId: string;
  stateKey: string;
} => {
  const { skillSubmissionRecords, skillStaticData } = getCwStore(globalState);
  if (isNil(skillSubmissionRecords) || isNil(skillStaticData)) {
    return null;
  }
  const parsedStateInfo = JSON.parse(skillSubmissionRecords[0].stateInfo);
  return {
    submissionData: parsedStateInfo.submissionData,
    vtwId: skillStaticData.vtwId,
    skillVersionVtwId: parsedStateInfo.skillVersionVtwId,
    skillStaticData: JSON.parse(skillStaticData.data),
    stateKey: skillSubmissionRecords[0].stateKey,
  };
};

const getBatchEditSelectedSyllabusItems = (globalState): SyllabusItemDto[] => {
  return getCwStore(globalState).batchEditSelectedSyllabusItems;
};

const getBatchEditUpdatedSyllabusItems = (globalState): SyllabusItemDto[] => {
  return getCwStore(globalState).batchEditUpdatedSyllabusItems;
};

const getABTestFlavors = (globalState): FeatureFlagDto[] => {
  return getCwStore(globalState).abTestFlavors;
};

const getUserEmailDomain = (globalState): string => {
  const user = getUser(globalState);
  const emailAddress = !user || !user.emailAddress ? ELSTokenHelper.getUserEmail() : user.emailAddress;
  if (!emailAddress) {
    return UNKNOWN;
  }
  const emailParts = emailAddress.split('@');
  if (!emailParts || emailParts.length !== 2) {
    return UNKNOWN;
  }
  return emailParts[1];
};

const isTestUser = (globalState): string => {
  const emailDomain = getUserEmailDomain(globalState);
  if (emailDomain === UNKNOWN) {
    return UNKNOWN;
  }

  const _isTestUser = ELSCommonConfig.testUserEmailDomains.some((domain) => {
    return emailDomain.includes(domain);
  });
  return _isTestUser ? TRUE_VALUE : FALSE_VALUE;
};

const getDefaultActionProps = (globalState): Record<string, string | number | string[] | number[]> => {
  const evolveUser = getEvolveUser(globalState);
  const institution = getInstitution(globalState);
  const abTestFlavors = getABTestFlavors(globalState);
  const props = {
    courseSectionId: getCourseSectionId(globalState),
    eolsUserId: getUserId(globalState),
    evolveUserId: evolveUser ? evolveUser.externalUserId : UNKNOWN,
    userRole: getRoleId(globalState),
    isbns: getRawIsbns(globalState),
    isbnsSorted: convertIsbnsToNormalizedString(getIsbns(globalState)),
    institution: institution ? institution.name : null,
    userEmailDomain: getUserEmailDomain(globalState),
    isTestUser: isTestUser(globalState),
    isMasqueradeUser: ELSTokenHelper.isMasqueradeUser(),
    pathname: locationSelectors.getLocation(globalState).pathname,
    institutionNormalized: getNormalizedInstitution(getUserEmailDomain(globalState)),
    applicationId: Application.ASSIGNMENT_MANAGEMENT,
    ...getABPropsForAnalytics(abTestFlavors)
  };
  return scrubProps(props);
};

const getDefaultAdobeAnalyticsPageData = (globalState): AdobeAnalyticsPageData => {

  const pageData: AdobeAnalyticsPageData = window && window.pageData
    ? window.pageData as AdobeAnalyticsPageData
    : getEmptyPageData();

  return {
    ...pageData,
    education: {
      ...pageData.education,
      courseId: getCourseSectionId(globalState),
      courseName: getCourseName(globalState),
      isbn: convertIsbnsToNormalizedString(getIsbns(globalState)),
      studentId: isStudent(getRoleId(globalState)) ? pageData.visitor.userId || getUserId(globalState) : '',
      instructorId: isInstructor(getRoleId(globalState)) ? pageData.visitor.userId || getUserId(globalState) : '',
      semester: getSemester(),
    }
  };
};

const getUserEngagementReport = (globalState): EolsUserEngagementDto[] => {
  return getCwStore(globalState).userEngagementReport;
};

const getModuleSequenceMap = (globalState): SequenceMap => {
  return getCwStore(globalState).moduleSequenceMap;
};

const getAssessments = (globalState): AssessmentDto[] => {
  return getCwStore(globalState).assessments;
};

const getEnableDeepLink = (globalState): boolean => {
  return getCwStore(globalState).enableDeepLink;
};

const getAssignmentInProgressAssessmentMap = createSelector(
  [getAssessments],
  (assessments): Record<number, number> => {
    return assessments.reduce((assignmentInProgressAssessmentMap, curAssessment): Record<number, number> => {
      if (curAssessment.status === AssessmentStatusDto.IN_PROGRESS) {
        return {
          ...assignmentInProgressAssessmentMap,
          [curAssessment.assignmentId]: curAssessment.id,
        };
      }
      return assignmentInProgressAssessmentMap;
    }, {});
  }
);

const getHasRunAuthessHealthCheck = (globalState): boolean => {
  return getCwStore(globalState).hasRunAuthessHealthCheck;
};

const getOsmosisTokenDto = (globalState): OsmosisTokenDto => {
  return getCwStore(globalState).osmosisTokenDto;
};

const getMigratedEntitlements = (globalState): MigratedCourseEntitlementDto[] => {
  return getCwStore(globalState).migratedEntitlements;
};

const getAssignment = (globalState): AssignmentDto => {
  return getCwStore(globalState).assignment;
};

const getInteractiveReviewCatalogItem = (globalState): RecContentItemDto => {
  return getCwStore(globalState).interactiveReviewCatalogItem;
};

const getImportSelection = (globalState): Partial<AssignmentDto> => {
  return getCwStore(globalState).importSelection;
};

export const cwSelectors = {
  getCwStore,
  getCourse,
  getCourseName,
  getIsCourseLocked,
  getUserHistory,
  getUserHistoryStateCompletedRequests,
  getCourseSectionId,
  getCurrentUserCourseOwnerRecord,
  getCurrentUserCourseOwnerRecords,
  getErrors,
  getEvolveProducts,
  getInstitution,
  getIsbns,
  getIsCourseOwner,
  getIsLoading,
  getIsLoadingLanguage,
  getMessages,
  getRoleId,
  getToastableErrors,
  getUser,
  getUserDisplayName,
  getUserId,
  getRegisteredToken,
  getUsers,
  getEaqUsers,
  getEaqIsbns,
  getMasteryResetSuccess,
  getCatalog,
  getPrimaryTaxonomies,
  getTaxonomies,
  getAllSyllabusItems,
  getSyllabusItems,
  getAssignments,
  getAssignmentsDictionary,
  getExternalEntities,
  getSyllabusFolders,
  getIsBatchEditModeEnabled,
  getIsDragDropModeEnabled,
  getCheckedSyllabusItemIds,
  getCourseBuilderState,
  getAssessmentSubmissionsMap,
  getEolsUserCourseSections,
  getEolsCrosswalkUserCourseSections,
  getResourceStatusMap,
  getEvolveUser,
  getAssessmentStartTimeMap,
  getFeatureFlagsGrouped,
  getAppLinkCookies,
  getLinkData,
  getCollapsedFolderIds,
  getSkillSubmissionData,
  getBatchEditSelectedSyllabusItems,
  getBatchEditUpdatedSyllabusItems,
  getDefaultActionProps,
  getIsMaintenance,
  isTestUser,
  getUserEngagementReport,
  getModuleSequenceMap,
  getAssessments,
  getAssignmentInProgressAssessmentMap,
  getGroupActivity,
  getDefaultAdobeAnalyticsPageData,
  getEnableDeepLink,
  getHasRunAuthessHealthCheck,
  getConflictErrors,
  getOsmosisTokenDto,
  getMigratedEntitlements,
  getSyllabusFolderInfo,
  getABTestFlavors,
  getAssignment,
  getInteractiveReviewCatalogItem,
  getImportSelection
};
