import {
  ActionMenuItemConfig,
  ActionMenuItemType,
} from './syllabus.models';
import {
  AssessmentStatusDto,
  AssignmentGradeType,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  ResourceCategory,
  SyllabusItemAction,
  SyllabusItemTypeConfig,
  SyllabusItemTypeConfigMap
} from '../../constants/content-type.constants';
import { ActiveSyllabusItemTypeDto, } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';

export const DEFAULT_NEST_LEVEL = 2;
export const MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH = 255;

export enum CoursePlanPageParam {
  SCROLLING_ID = 'scrollingId',
  ADDED_SYLLABUS_ITEM_ID = 'addedSyllabusItemId',
  IS_SMOOTH_SCROLL_DISABLED = 'isSmoothScrollDisabled',
  UPDATED_SYLLABUS_ITEM_ID = 'updatedSyllabusItemId',
  BULK_EDIT_SETTINGS_RETURN_ACTION = 'bulkEditSettingsReturnAction'
}

export const SyllabusItemActionConfigMap: Record<SyllabusItemAction, {
  iconName: string;
  iconPrefix: string;
  title: string;
  contentType?: string;
}> = {
  [SyllabusItemAction.ADD_A_FOLDER]: {
    iconName: 'open-folder',
    iconPrefix: 'gizmo',
    title: 'Add a folder',
  },
  [SyllabusItemAction.ADD_ADAPTIVE_LESSON]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON].iconPrefix,
    title: 'Add Lessons',
    contentType: 'Lesson',
  },
  [SyllabusItemAction.ADD_ADAPTIVE_QUIZ]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ].iconPrefix,
    title: 'Add Adaptive Quiz (EAQ)',
    contentType: 'Adaptive Quiz (EAQ)',
  },
  [SyllabusItemAction.ADD_EBOOK_READING]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.EBOOK_READING].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.EBOOK_READING].iconPrefix,
    title: 'Add eBook Reading',
    contentType: 'eBook Reading',
  },
  [SyllabusItemAction.ADD_SIMCHART]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SIMCHART_CHART].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SIMCHART_CHART].iconPrefix,
    title: 'Add SimChart',
    contentType: 'SimChart',
  },
  [SyllabusItemAction.ADD_SHERPATH_LESSONS]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_LESSON].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_LESSON].iconPrefix,
    title: 'Add Lessons',
    contentType: 'Lesson',
  },
  [SyllabusItemAction.ADD_SHERPATH_SKILLS]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_SKILL].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_SKILL].iconPrefix,
    title: 'Add Skills',
    contentType: 'Skill',
  },
  [SyllabusItemAction.ADD_SHERPATH_SIMULATIONS]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_SIMULATIONS].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_SIMULATIONS].iconPrefix,
    title: 'Add Virtual Simulations',
    contentType: 'Simulations',
  },
  [SyllabusItemAction.ADD_EVOLVE_INSTRUCTOR_RESOURCES]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE].iconPrefix,
    title: 'Add Evolve Instructor Resources',
    contentType: 'Evolve Instructor Resource',
  },
  [SyllabusItemAction.ADD_EVOLVE_STUDENT_RESOURCES]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE].iconPrefix,
    title: 'Add Evolve Student Resources',
    contentType: 'Evolve Student Resource',
  },
  [SyllabusItemAction.ADD_SHERPATH_GROUP_ACTIVITY]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_GROUP_ACTIVITY].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_GROUP_ACTIVITY].iconPrefix,
    title: 'Add Group Activity',
    contentType: 'Sherpath Group Activity',
  },
  [SyllabusItemAction.ADD_SHADOW_HEALTH]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT].iconPrefix,
    title: 'Add Shadow Health Assignment',
    contentType: 'Shadow Health',
  },
  [SyllabusItemAction.ADD_SHERPATH_POWERPOINT]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT].iconPrefix,
    title: 'Add PowerPoint',
    contentType: 'Sherpath Powerpoint',
  },
  [SyllabusItemAction.ADD_SHERPATH_CASE_STUDY]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_CASE_STUDY].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.SHERPATH_CASE_STUDY].iconPrefix,
    title: 'Add Case Study',
    contentType: 'Sherpath Case Study',
  },
  [SyllabusItemAction.ADD_ASSESSMENT_BUILDER]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.ASSESSMENT_BUILDER_ASSIGNMENT].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.ASSESSMENT_BUILDER_ASSIGNMENT].iconPrefix,
    title: 'Add/Manage Banks and Assessments (EAB)',
    contentType: 'Assessment Builder Assignment',
  },
  [SyllabusItemAction.ADD_OSMOSIS_VIDEO]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO].iconPrefix,
    title: 'Add Osmosis Video',
    contentType: 'Osmosis Video'
  },
  [SyllabusItemAction.ADD_EXTERNAL_LINK]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.CUSTOM_LINK].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.CUSTOM_LINK].iconPrefix,
    title: 'Add External Link',
    contentType: 'External Link',
  },
  [SyllabusItemAction.ADD_INTERACTIVE_REVIEW]: {
    iconName: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.INTERACTIVE_REVIEW].icon,
    iconPrefix: SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.INTERACTIVE_REVIEW].iconPrefix,
    title: 'Add Interactive Review Questions',
    contentType: 'Interactive Review Questions',
  },
  [SyllabusItemAction.ADD_MORE_RESOURCES]: {
    iconName: 'add-document',
    iconPrefix: 'gizmo',
    title: 'Add more resources',
  },
  [SyllabusItemAction.BULK_MOVE_REORDER]: {
    iconName: 'shuffle',
    iconPrefix: 'gizmo',
    title: 'Bulk move / reorder',
  },
  [SyllabusItemAction.BULK_UNASSIGN]: {
    iconName: 'calendar-not-available',
    iconPrefix: 'hmds',
    title: 'Bulk unassign',
  },
  [SyllabusItemAction.BULK_EDIT_SETTING]: {
    iconName: 'calendar',
    iconPrefix: 'gizmo',
    title: 'Bulk edit setting',
  },
  [SyllabusItemAction.BULK_MAKE_VISIBLE_NOW]: {
    iconName: 'eye',
    iconPrefix: 'gizmo',
    title: 'Bulk make visible now',
  },
  [SyllabusItemAction.BULK_REMOVE]: {
    iconName: 'caution',
    iconPrefix: 'gizmo',
    title: 'Bulk remove',
  },
  [SyllabusItemAction.DEV_ADD_ASSIGNMENT]: {
    iconName: 'plus',
    iconPrefix: 'gizmo',
    title: 'DEV ADD ASSIGNMENT',
  },
  [SyllabusItemAction.MOVE]: {
    iconName: 'shuffle',
    iconPrefix: 'gizmo',
    title: 'Move / reorder'
  },
  [SyllabusItemAction.UNASSIGN]: {
    iconName: 'calendar-not-available',
    iconPrefix: 'hmds',
    title: 'Unassign',
  },
  [SyllabusItemAction.REMOVE]: {
    iconName: 'caution',
    iconPrefix: 'gizmo',
    title: 'Remove',
  },
  [SyllabusItemAction.RENAME]: {
    iconName: 'pencil',
    iconPrefix: 'gizmo',
    title: 'Rename',
  },
  [SyllabusItemAction.MAKE_VISIBLE_NOW]: {
    iconName: 'eye',
    iconPrefix: 'gizmo',
    title: 'Make visible now',
  },
  [SyllabusItemAction.VIEW]: {
    iconName: 'eye-care',
    iconPrefix: 'gizmo',
    title: 'View',
  },
  [SyllabusItemAction.SETTINGS]: {
    iconName: 'settings',
    iconPrefix: 'gizmo',
    title: 'Settings',
  },
  [SyllabusItemAction.VIEW_PERFORMANCE]: {
    iconName: 'performance',
    iconPrefix: 'hmds',
    title: 'Scores',
  },
  [SyllabusItemAction.CREATE_DEEP_LINK]: {
    iconName: 'settings',
    iconPrefix: 'gizmo',
    title: 'Create deep link',
  },
  [SyllabusItemAction.DESELECT_ALL]: null,
  [SyllabusItemAction.SELECT_ALL]: null,
};

export const MenuDivider: ActionMenuItemConfig = {
  type: ActionMenuItemType.DIVIDER
};

export const OrderedBulkModeActions: ActionMenuItemConfig[] = [
  {
    action: SyllabusItemAction.BULK_MOVE_REORDER,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.BULK_REMOVE,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.BULK_UNASSIGN,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.BULK_EDIT_SETTING,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.BULK_MAKE_VISIBLE_NOW,
    type: ActionMenuItemType.ACTION
  },
];

export const OrderedFolderActions: ActionMenuItemConfig[] = [
  {
    action: SyllabusItemAction.RENAME,
    type: ActionMenuItemType.ACTION
  }, {
    action: SyllabusItemAction.MOVE,
    type: ActionMenuItemType.ACTION
  }
];

const getOrderedAddItemOptions = () => {
  const addItemOptions = [
    ResourceCategory.QUIZZES_ASSESSMENTS,
    ResourceCategory.CONTENT_RESOURCES,
    ResourceCategory.CLINICAL_RESOURCES,
  ].reduce((acc, cur) => {
    const categoryActions = Object.values(SyllabusItemTypeConfigMap)
      .reduce((_acc: SyllabusItemTypeConfig[], config: SyllabusItemTypeConfig) => {
        if (config.resourceCategory !== cur) {
          return _acc;
        }
        const hasDup = _acc.some((item: SyllabusItemTypeConfig) => {
          return item.syllabusItemAction === config.syllabusItemAction;
        });
        if (hasDup) {
          return _acc;
        }
        return [
          ..._acc,
          config
        ];
      }, [])
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      })
      .map((config) => {
        return {
          action: config.syllabusItemAction,
          type: ActionMenuItemType.ACTION
        };
      });
    return [
      ...acc,
      ...categoryActions,
      MenuDivider
    ];
  }, []);
  return [
    ...addItemOptions, {
      action: SyllabusItemAction.ADD_MORE_RESOURCES,
      type: ActionMenuItemType.ACTION
    }
  ];
};

export const OrderedAddItemOptions: ActionMenuItemConfig[] = getOrderedAddItemOptions();

export const OrderedAddResourceItemOptions: ActionMenuItemConfig[] = [
  ...OrderedAddItemOptions.filter((option) => option.type !== ActionMenuItemType.DIVIDER)
];

export const OrderedSubFolderActions: ActionMenuItemConfig[] = [
  {
    action: SyllabusItemAction.ADD_A_FOLDER,
    type: ActionMenuItemType.ACTION
  }
];

export const OrderedFolderActionMenuOptions: ActionMenuItemConfig[] = [
  ...OrderedBulkModeActions,
  MenuDivider,
  ...OrderedFolderActions,
  MenuDivider,
  ...OrderedAddItemOptions,
  MenuDivider,
  ...OrderedSubFolderActions,
  MenuDivider,
  {
    action: SyllabusItemAction.REMOVE,
    type: ActionMenuItemType.ACTION
  }
];

export const OrderedSyllabusItemActions: ActionMenuItemConfig[] = [
  {
    action: SyllabusItemAction.BULK_MOVE_REORDER,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.BULK_REMOVE,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.BULK_UNASSIGN,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.BULK_EDIT_SETTING,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.BULK_MAKE_VISIBLE_NOW,
    type: ActionMenuItemType.ACTION
  },
  MenuDivider,
  {
    action: SyllabusItemAction.SETTINGS,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.MAKE_VISIBLE_NOW,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.VIEW,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.VIEW_PERFORMANCE,
    type: ActionMenuItemType.ACTION
  },
  {
    action: SyllabusItemAction.MOVE,
    type: ActionMenuItemType.ACTION
  }, {
    action: SyllabusItemAction.UNASSIGN,
    type: ActionMenuItemType.ACTION
  }, {
    action: SyllabusItemAction.REMOVE,
    type: ActionMenuItemType.ACTION
  }, {
    action: SyllabusItemAction.CREATE_DEEP_LINK,
    type: ActionMenuItemType.ACTION
  }, {
    action: SyllabusItemAction.DEV_ADD_ASSIGNMENT,
    type: ActionMenuItemType.ACTION
  }
];

export const PLACE_NEW_ASSIGNMENTS_MODAL_TITLE = 'Place New Assignments';

export enum AssignmentStatus {
  COMPLETED_BEFORE_DUE_DATE = 'Completed Before Due Date',
  COMPLETED_AFTER_DUE_DATE = 'Completed After Due Date',
  IN_PROGRESS_PAST_DUE = 'In Progress Past Due',
  IN_PROGRESS = 'In Progress',
  NOT_STARTED_PAST_DUE = 'Not Started Past Due',
  NOT_STARTED = 'Not Started',
}

export const AssessmentStatusIconMap: Record<AssessmentStatusDto, {
  iconName: string;
  iconPrefix: string;
}> = {
  [AssessmentStatusDto.COMPLETED]: {
    iconPrefix: 'gizmo',
    iconName: 'check',
  },
  [AssessmentStatusDto.NOT_STARTED]: {
    iconPrefix: 'hmds',
    iconName: 'circle'
  },
  [AssessmentStatusDto.IN_PROGRESS]: {
    iconPrefix: 'gizmo',
    iconName: 'record'
  }
};

export const AssignmentStatusIconMap: Record<AssignmentStatus, {
  iconName: string;
  iconPrefix: string;
  iconClass: string;
}> = {
  [AssignmentStatus.COMPLETED_AFTER_DUE_DATE]: {
    ...AssessmentStatusIconMap[AssessmentStatusDto.COMPLETED],
    iconClass: 'u-els-color-confirm'
  },
  [AssignmentStatus.COMPLETED_BEFORE_DUE_DATE]: {
    ...AssessmentStatusIconMap[AssessmentStatusDto.COMPLETED],
    iconClass: 'u-els-color-confirm'
  },
  [AssignmentStatus.IN_PROGRESS]: {
    ...AssessmentStatusIconMap[AssessmentStatusDto.IN_PROGRESS],
    iconClass: 'u-els-color-secondary'
  },
  [AssignmentStatus.IN_PROGRESS_PAST_DUE]: {
    ...AssessmentStatusIconMap[AssessmentStatusDto.IN_PROGRESS],
    iconClass: 'u-els-color-warn'
  },
  [AssignmentStatus.NOT_STARTED_PAST_DUE]: {
    ...AssessmentStatusIconMap[AssessmentStatusDto.NOT_STARTED],
    iconClass: 'u-els-color-warn'
  },
  [AssignmentStatus.NOT_STARTED]: {
    ...AssessmentStatusIconMap[AssessmentStatusDto.NOT_STARTED],
    iconClass: 'u-els-color-secondary'
  },
};

export const adaptiveLessonMainTypes = ['LESSONQUIZ'];
export const adaptiveLessonSubTypes = ['KEYCONCEPTS', 'KNOWLEDGECHECK', 'PRACTICE', 'LESSONQUIZ'];

export enum CatalogResourceStatus {
  ADDED = 'added',
  NOT_ADDED = 'not_added',
  DUE = 'due',
  VISIBLE = 'visible',
}

export type CatalogResourceStatusMapType = Record<CatalogResourceStatus, {
  display: string;
  value: CatalogResourceStatus;
}>

export const CatalogResourceStatusMap: CatalogResourceStatusMapType = {
  [CatalogResourceStatus.ADDED]: {
    display: 'Added',
    value: CatalogResourceStatus.ADDED,
  },
  [CatalogResourceStatus.NOT_ADDED]: {
    display: 'Not Added',
    value: CatalogResourceStatus.NOT_ADDED
  },
  [CatalogResourceStatus.DUE]: {
    display: 'Due',
    value: CatalogResourceStatus.DUE,
  },
  [CatalogResourceStatus.VISIBLE]: {
    display: 'Visible',
    value: CatalogResourceStatus.VISIBLE,
  },
};

export enum CoursePlanResourceStatus {
  DUE = 'due',
  VISIBLE = 'visible',
  NOT_DUE = 'not_due',
  NOT_VISIBLE = 'not_visible',
}

export type CoursePlanResourceStatusMapType = Record<CoursePlanResourceStatus, {
  display: string;
  value: CoursePlanResourceStatus;
}>

export const CoursePlanResourceStatusMap: CoursePlanResourceStatusMapType = {
  [CoursePlanResourceStatus.DUE]: {
    display: 'Due',
    value: CoursePlanResourceStatus.DUE,
  },
  [CoursePlanResourceStatus.VISIBLE]: {
    display: 'Visible',
    value: CoursePlanResourceStatus.VISIBLE,
  },
  [CoursePlanResourceStatus.NOT_DUE]: {
    display: 'Not Due',
    value: CoursePlanResourceStatus.NOT_DUE
  },
  [CoursePlanResourceStatus.NOT_VISIBLE]: {
    display: 'Not Visible',
    value: CoursePlanResourceStatus.NOT_VISIBLE,
  },
};

export type CoursePlanResourceGradingMapType = Record<AssignmentGradeType, {
  display: string;
  value: AssignmentGradeType;
}>

export const CoursePlanResourceGradingMap: CoursePlanResourceGradingMapType = {
  [AssignmentGradeType.NOT_GRADED]: {
    display: 'Not Graded',
    value: AssignmentGradeType.NOT_GRADED,
  },
  [AssignmentGradeType.SCORED]: {
    display: 'Scored',
    value: AssignmentGradeType.SCORED,
  },
  [AssignmentGradeType.PASS_FAIL]: {
    display: 'Pass Fail',
    value: AssignmentGradeType.PASS_FAIL,
  }
};

export const CONFIRM_PAST_DUE_MODAL_ID = 'CONFIRM_PAST_DUE_MODAL_ID';

export enum IndicatorLocation {
  ABOVE = 'above',
  BELOW = 'below'
}

export const LessonSyllabusItemTypes: ActiveSyllabusItemTypeDto[] = [
  ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON,
  ActiveSyllabusItemTypeDto.SHERPATH_LESSON
];

export const AssignmentStatusOrderMap: Record<AssessmentStatusDto, number> = {
  [AssessmentStatusDto.NOT_STARTED]: 3,
  [AssessmentStatusDto.IN_PROGRESS]: 2,
  [AssessmentStatusDto.COMPLETED]: 1
};

export const ResourceCategoryTitleMap: Record<ResourceCategory, string> = {
  [ResourceCategory.QUIZZES_ASSESSMENTS]: 'Quizzes/Assessments',
  [ResourceCategory.CONTENT_RESOURCES]: 'Content Resources',
  [ResourceCategory.CLINICAL_RESOURCES]: 'Clinical Resources',
};
