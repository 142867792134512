/* eslint-disable max-len */
import { LANGUAGE_KEY } from './message.constants';

const en: Record<LANGUAGE_KEY, string> = {
  [LANGUAGE_KEY.ACTION]: 'Action',
  [LANGUAGE_KEY.ACTIONS]: 'Actions',
  [LANGUAGE_KEY.ACTIONS_RESULTS_MESSAGE]: 'Information is based on each student\'s best attempt.',
  [LANGUAGE_KEY.ADD]: 'Add',
  [LANGUAGE_KEY.ADD_A_FOLDER]: 'Add a Folder',
  [LANGUAGE_KEY.ADD_A_RESOURCE]: 'Add a resource',
  [LANGUAGE_KEY.ADD_DATE_RANGE_TO_EACH_UNIT]: 'Add date range to each {unit}',
  [LANGUAGE_KEY.ADD_RESOURCES]: 'Add Resources',
  [LANGUAGE_KEY.ADD_MORE_RESOURCES]: 'Add more resources',
  [LANGUAGE_KEY.ADD_SINGLE_RESOURCE]: 'Add Resource to Course Plan',
  [LANGUAGE_KEY.ADDITIONAL_LESSONS_COMING_SOON]: 'Additional Lessons coming soon.',
  [LANGUAGE_KEY.ALL_ATTEMPTS_TITLE]: 'All Attempts',
  [LANGUAGE_KEY.ALL_ITEMS]: 'All Items',
  [LANGUAGE_KEY.ALL_VIEWS]: 'All Views',
  [LANGUAGE_KEY.ALREADY_UNASSIGNED_SKIPPED]: 'Already unassigned items to be skipped:',
  [LANGUAGE_KEY.APPLICATION]: 'Application',
  [LANGUAGE_KEY.ARE_YOU_SURE]: 'Are you sure?',
  [LANGUAGE_KEY.AREA]: 'Area',
  [LANGUAGE_KEY.ASSIGNMENT_CAN_NOT_BE_UNASSIGNED]: 'If an assignment is graded and in progress or completed by any student, it cannot be unassigned',
  [LANGUAGE_KEY.ASSIGNMENT_PERFORMANCE]: 'Assignment Performance',
  [LANGUAGE_KEY.ASSIGNMENT_REPORT_TITLE]: 'Assignment Report',
  [LANGUAGE_KEY.ASSIGNMENT_SUBMISSIONS_TITLE]: 'Assignment Submissions',
  [LANGUAGE_KEY.ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_PASS_FAIL_GRADING]: 'Assignment type does not support pass/fail grading',
  [LANGUAGE_KEY.ASSIGNMENT_TYPE_DOES_NOT_SUPPORT_SCORE_GRADING]: 'Assignment type does not support scored grading',
  [LANGUAGE_KEY.ATTEMPT]: 'Attempt',
  [LANGUAGE_KEY.AVERAGE_ACTION_SCORE_ON_BEST_ATTEMPT]: 'Average Action Score on Best Attempt',
  [LANGUAGE_KEY.AVERAGE_QUESTION_PERFORMANCE]: 'Average Question Performance',
  [LANGUAGE_KEY.AVERAGE_QUESTIONS_MESSAGE]: 'Information is based on each student\'s highest scored attempt before the due date. Click each question to view the correct answers.',
  [LANGUAGE_KEY.AVERAGE_SCORE]: 'Average Score',
  [LANGUAGE_KEY.BATCH_ACTION_BAR_EDIT_CLOSE]: 'Close',
  [LANGUAGE_KEY.BATCH_ACTION_BAR_EDIT_DATES_NO_ITEMS_SELECTED]: 'No items selected. Please select at least one item to edit dates.',
  [LANGUAGE_KEY.BATCH_ACTIONS_BAR_DESELECT_ALL]: 'Deselect All',
  [LANGUAGE_KEY.BATCH_ACTIONS_BAR_EDIT_DATES]: 'Edit settings',
  [LANGUAGE_KEY.BATCH_ACTIONS_BAR_MAKE_VISIBLE_NOW]: 'Make visible now',
  [LANGUAGE_KEY.BATCH_ACTIONS_BAR_MOVE_REORDER]: 'Move / reorder',
  [LANGUAGE_KEY.BATCH_ACTIONS_BAR_REMOVE]: 'Remove',
  [LANGUAGE_KEY.BATCH_ACTIONS_BAR_SELECT_ALL]: 'Select All',
  [LANGUAGE_KEY.BATCH_ACTIONS_BAR_UNASSIGN]: 'Unassign',
  [LANGUAGE_KEY.BOOK_SECTION]: 'Book section',
  [LANGUAGE_KEY.BULK_ADD]: 'Bulk add',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_AVAILABLE_FROM_COL]: 'Available From',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_BY_DAYS]: 'By days',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_BY_HOURS]: 'By hours',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_BY_MINUTES]: 'By minutes',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_BY_WEEKS]: 'By weeks',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_CANCEL_BTN]: 'Cancel',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_CHANGE_ALL_DATES_TAB]: 'Change all dates',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_DUE_COL]: 'Due',
  [LANGUAGE_KEY.DUE]: 'Due',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_EDIT_GRADING_STARTED_ASSIGNMENT_WARNING]: 'Grading type cannot be updated on assignments in progress or completed',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_SKIPPED_WARNING]: 'Due date or grading type was not applied to unpublished Lessons.\nThese options can be applied once a Lesson has been released.',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_UNPUBLISHED_LESSON_WARNING]: '{syllabusItemName} Due date and grading type cannot be applied to unpublished Lessons',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB]: 'Edit settings',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_AVAILABLE_DATE]: 'Availability Date',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_AVAILABLE_DATE_NO_DATE]: 'No Availability Date',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_DUE_DATE]: 'Due Date',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_DUE_DATE_NO_DATE]: 'No Due Date',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_CHANGE_ALL_GRADING_STARTED_ASSIGNMENT_WARNING]: 'Grading type was not applied to assignments in progress or completed',
  [LANGUAGE_KEY.APPLY_TO_ALL]: 'Apply to all',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_HEADING]: 'Change availability and due dates',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_AVAILABLE_DATE]: 'Not visible',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_DUE_DATE]: 'Not due',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EDIT_SETTINGS_TAB_NO_GRADING]: 'No Grading',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_EXTEND_ALL_DATES_TAB]: 'Extend all dates',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_GRADE_TYPE_UNCHANGED_MODAL]: 'Grading was not applied because {notSupportedGrade} grade type is not supported by {assignmentType} assignments',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_HEADER]: 'Bulk edit selected items',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_INCLUDE_AVAILABLE_COL]: 'Include Available Date',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_INCLUDE_DUE_COL]: 'Include Due Date',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_PUSH_AVAILABILITY_DATES]: 'Push availability dates',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_PUSH_DUE_DATES]: 'Push due dates',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_RESOURCE_NAME_COL]: 'Resource Name',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_RESOURCE_TYPE_COL]: 'Resource Type',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_SHIFT_DATES_TAB]: 'Shift dates',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_PAGE_SUBMIT_BTN]: 'Save and Complete',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_SUBMIT_TOAST_ERROR]: 'Please enter a valid date.',
  [LANGUAGE_KEY.BULK_EDIT_SETTINGS_TEACHING_RESOURCE_WARNING]: 'Please note that the due date and grading type will not be applied to teaching resources.',
  [LANGUAGE_KEY.BULK_SELECT]: 'Bulk Select',
  [LANGUAGE_KEY.BUTTON_ADD]: 'Add',
  [LANGUAGE_KEY.BUTTON_ADD_A_FOLDER]: 'Add a Folder',
  [LANGUAGE_KEY.BUTTON_ADD_MORE_RESOURCES]: 'Add more resources ...',
  [LANGUAGE_KEY.CONFIRM]: 'Confirm',
  [LANGUAGE_KEY.CANCEL]: 'Cancel',
  [LANGUAGE_KEY.CANCEL_AND_RETURN_TO_PREVIOUS_PAGE]: 'Cancel and return to previous page',
  [LANGUAGE_KEY.CANNOT_BE_UNASSIGNED]: 'If an item is graded and in progress or completed, it cannot be unassigned.',
  [LANGUAGE_KEY.CASE_STUDY]: 'Case Study',
  [LANGUAGE_KEY.CATALOG]: 'Catalog',
  [LANGUAGE_KEY.CATALOG_ADD_CONFIRM_MESSAGE]: '{numSelectedItemsOnOtherPages} of the {numTotalSelectedItems} selected items are on other pages. Are you sure you would like to add all {numTotalSelectedItems} selected items?',
  [LANGUAGE_KEY.CATALOG_ITEM_STATUS]: '{type, select, not_added {Not Added} added {Added in {folderTitle}} visible {Visible in {folderTitle}} assigned {Assigned in {folderTitle}}}',
  [LANGUAGE_KEY.CATALOG_ITEM_STATUS_VIEW_LESS_BTN]: 'View less',
  [LANGUAGE_KEY.CATALOG_ITEM_STATUS_VIEW_MORE_BTN]: 'View {remainFoldersCount} more',
  [LANGUAGE_KEY.CHANGE_COURSE]: 'Change Course',
  [LANGUAGE_KEY.CLASS_AVERAGE_ON_ALL_SUBMISSIONS]: 'Class average on all submissions',
  [LANGUAGE_KEY.CLASS_AVERAGE_ON_VIDEO_COMPLETION_FOR_ALL_VIEWS]: 'Class average on video completion for all views',
  [LANGUAGE_KEY.CLEAR_FILTERS]: 'Clear filters',
  [LANGUAGE_KEY.CLEAR_SEARCH]: 'Clear search',
  [LANGUAGE_KEY.CLICK_HERE]: 'click here',
  [LANGUAGE_KEY.COLLAPSE_ALL_FOLDERS]: 'Collapse all folders',
  [LANGUAGE_KEY.COMING_SOON]: 'Coming soon',
  [LANGUAGE_KEY.COMPLETE_STUDENTS_TITLE]: 'Students with submissions',
  [LANGUAGE_KEY.COMPLETED]: 'Completed',
  [LANGUAGE_KEY.COMPLETED_STUDENTS]: 'Completed students',
  [LANGUAGE_KEY.CONFIRM_ACTION_ON_HIDDEN_SYLLABUS]: 'This action affects {numberOfHiddenSyllabus} hidden items because of active course plan filters. Are you sure you would like to proceed?',
  [LANGUAGE_KEY.CONFIRM_SELECTED_ITEMS]: 'Confirm selected items',
  [LANGUAGE_KEY.CONFIRM_UNASSIGN_MODAL]: 'This assignment has already been started by some students. Do you still want to unassign it?',
  [LANGUAGE_KEY.COPY_COURSE_HAS_CONTENT_MESSAGE]: 'Preview displays the contents of the course that you selected to copy. All folders and assignments shown in the Preview will be carried over to your new course. You can change the folder labels from Course Plan after creating your new course.',
  [LANGUAGE_KEY.COPY_COURSE_NO_CONTENT_MESSAGE]: 'The course you selected does not have any items in the Course Plan. Please select another course to copy.',
  [LANGUAGE_KEY.COURSE_BUILDER_PREVIEW_DEFAULT_MESSAGE]: 'You will be able to add, remove, and move items after the course is created',
  [LANGUAGE_KEY.COURSE_BUILDER_WHICH_CONTENT_TYPES]: 'Which content types would you like to include in your course?',
  [LANGUAGE_KEY.COURSE_COPY_UNMAPPED_ITEMS_TITLE]: 'Items that cannot be programmatically copied',
  [LANGUAGE_KEY.COURSE_COPY_WARN_MSG_PART_1A]: '{items} cannot be copied to the new course.',
  [LANGUAGE_KEY.COURSE_COPY_WARN_MSG_PART_2A]: 'NAQs are not supported in upgraded Sherpath.',
  [LANGUAGE_KEY.COURSE_COPY_WARN_MSG_PART_2B]: 'Please contact <link>Help and Support</link> to resolve this issue before creating course or proceed with creating course as seen in the preview.',
  [LANGUAGE_KEY.COURSE_END_DATE]: 'Course end date',
  [LANGUAGE_KEY.COURSE_LOCKED_NOTIFICATION]: 'This course is locked. Contact your course creator to make changes.',
  [LANGUAGE_KEY.COURSE_NAME]: 'Course name',
  [LANGUAGE_KEY.COURSE_OWNER_LOCKED_NOTIFICATION]: 'Your course is locked. You can unlock the course through <button>Course Settings</button> to make changes.',
  [LANGUAGE_KEY.COURSE_START_DATE]: 'Course start date',
  [LANGUAGE_KEY.CREATE_YOUR_COURSE]: 'Create Your Course',
  [LANGUAGE_KEY.DATE_CREATED]: 'Date created',
  [LANGUAGE_KEY.DEFAULT_TAB]: 'Default Tab',
  [LANGUAGE_KEY.DISMISS]: 'Dismiss',
  [LANGUAGE_KEY.DISMISS_ALL]: 'Dismiss All',
  [LANGUAGE_KEY.DRAG_DROP_TOGGLE]: 'Drag and Drop',
  [LANGUAGE_KEY.DUE_BY]: 'Due by',
  [LANGUAGE_KEY.DUE_DATE]: 'Due date',
  [LANGUAGE_KEY.DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE]: 'The availability date/time must precede the due date/time',
  [LANGUAGE_KEY.DUE_DATE_IS_REQUIRED_FOR_ALL_EAQ_ASSIGNMENTS]: 'Due date is required for all EAQ assignments',
  [LANGUAGE_KEY.DUE_DATE_RESULTS_MESSAGE]: 'Displayed score is based on best submission attempt before assignment due date.  If the assignment does not have a due date or all the user\'s submission attempts are late then the displayed score is based on the best of all submission attempts.',
  [LANGUAGE_KEY.DUE_DATE_RESULTS_MESSAGE_SIMULATION_LINE1]: 'Displayed score is based on best submission attempt before assignment due date. '
    + 'If the assignment does not have a due date or all the user\'s submission attempts are late then the displayed score is based on the best of all submission attempts.',
  [LANGUAGE_KEY.DUE_DATE_RESULTS_MESSAGE_SIMULATION_LINE2]: 'Average action score on best attempt column shows the best submission attempt score regardless of the assignment due date.',
  [LANGUAGE_KEY.DUE_ON_DATE_AT_TIME]: 'Due {dueDate} at {dueTime} {clientZoneAbbr}',
  [LANGUAGE_KEY.DURATION]: 'Duration',
  [LANGUAGE_KEY.E_G_NURSING_CARE]: 'e.g. Nursing care',
  [LANGUAGE_KEY.E_G_NAME]: 'e.g. John Smith',
  [LANGUAGE_KEY.E_G_SHERPATH_FOR_FUNDAMENTALS]: 'e.g. Sherpath for Fundamentals',
  [LANGUAGE_KEY.EDIT_SETTINGS_NO_ITEMS_SELECTED]: 'No items selected. Please select at least one item to edit settings.',
  [LANGUAGE_KEY.EMAIL]: 'Email',
  [LANGUAGE_KEY.ENTER_PAGE_NUMBER_AND_OR_PAGE_RANGES_SEPARATED_BY_COMMAS]: 'Enter page numbers and/or page ranges separated by commas (e.g. 2, 5-8)',
  [LANGUAGE_KEY.EXPAND_ALL_FOLDERS]: 'Expand all folders',
  [LANGUAGE_KEY.FAIL]: 'Fail',
  [LANGUAGE_KEY.FILTER_BY]: 'Filter by',
  [LANGUAGE_KEY.FILTER_BY_APPLICATION]: 'Filter By Application',
  [LANGUAGE_KEY.FILTER_BY_STATUS]: 'Filter by status',
  [LANGUAGE_KEY.FILTER_CONTENT_BY]: 'Filter content by',
  [LANGUAGE_KEY.FILTERS_ARE_ON]: 'Filters are on',
  [LANGUAGE_KEY.FIRST_FOLDER_TITLE]: 'First folder title',
  [LANGUAGE_KEY.GRADE]: 'Grade',
  [LANGUAGE_KEY.GRADE_TYPE]: 'Grade type',
  [LANGUAGE_KEY.GRADING]: 'Grading',
  [LANGUAGE_KEY.GRADE_TYPE_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT]: 'If an assignment is in progress or completed by any student, the grade type cannot be changed',
  [LANGUAGE_KEY.GRADE_TYPE_AND_GRADE_POINTS_CANNOT_BE_CHANGED_ON_STARTED_ASSIGNMENT]: 'If an assignment is in progress or completed by any student, the grade type and grade points cannot be changed',
  [LANGUAGE_KEY.GROUP_ACTIVITY_TITLE]: 'Group Activity',
  [LANGUAGE_KEY.HESI_FILTER_TOOLTIP]: 'This filter helps to identify chapters in this course that are most relevant to the corresponding HESI Specialty Exam.',
  [LANGUAGE_KEY.HESI_FOCUS_CHAPTERS]: 'HESI Focus Chapters',
  [LANGUAGE_KEY.HOW_DO_YOU_ORGANIZE_YOUR_SYLLABUS]: 'How do you organize your syllabus?',
  [LANGUAGE_KEY.HOW_MANY_UNIT_DO_YOU_NEED]: 'How many {unit} do you need?',
  [LANGUAGE_KEY.ICON_NO_PAST_ASSIGNMENTS]: 'No past assignments',
  [LANGUAGE_KEY.ICON_NO_UPCOMING_ASSIGNMENTS]: 'No upcoming assignments',
  [LANGUAGE_KEY.IN_ORDER_TO_GET_STARTED_PLEASE_SELECT_HOW_YOU_WOULD_LIKE_TO_ORGANIZE_YOUR_COURSE_RESOURCE]: 'In order to get started, please select how you\'\'d like to organize your course resources.',
  [LANGUAGE_KEY.IN_PROGRESS]: 'In progress',
  [LANGUAGE_KEY.IN_PROGRESS_OR_COMPLETED_GRADED_SKIPPED]: 'In progress or completed graded items to be skipped:',
  [LANGUAGE_KEY.INCOMPLETE_STUDENTS_TITLE]: 'Students without submissions',
  [LANGUAGE_KEY.INDICATES_REQUIRED_FIELD]: 'Indicates required field',
  [LANGUAGE_KEY.INFORMATION_TOOLTIP]: 'Information Tooltip',
  [LANGUAGE_KEY.INVALID_GRADE_POINTS]: 'Invalid grade points',
  [LANGUAGE_KEY.INVALID_PAGE_RANGE_ERROR]: 'Invalid page range',
  [LANGUAGE_KEY.LAST_DATE_WATCHED]: 'Last date watched',
  [LANGUAGE_KEY.LATEST_DATE_WATCHED]: 'Latest date watched',
  [LANGUAGE_KEY.LATE]: 'Late',
  [LANGUAGE_KEY.LATEST_ATTEMPT_SCORE_TITLE]: 'Latest Attempt Score',
  [LANGUAGE_KEY.LATEST_SUBMISSION_TIME]: 'Latest Submission Time',
  [LANGUAGE_KEY.LEARNING_DURATION]: 'Approx {learningDuration} min',
  [LANGUAGE_KEY.LOCKED]: 'Locked',
  [LANGUAGE_KEY.MAKE_VISIBLE_NOW_NO_ITEMS_SELECTED]: 'No items selected. Please select at least one item to make visible now.',
  [LANGUAGE_KEY.MAKE_VISIBLE_NOW_SUCCESS_TOAST]: '{nameOfItem} was successfully made visible.',
  [LANGUAGE_KEY.MAKE_VISIBLE_NOW_ALREADY_AVAILABLE_WARNING_TOAST]: 'The item you selected is already visible to students. Existing settings were not changed.',
  [LANGUAGE_KEY.MISSED_ACTIONS]: 'Missed Actions',
  [LANGUAGE_KEY.MOVE_REORDER_ALL_ITEM_CANNOT_BE_SELECTED_TO_BE_MOVED_AT_ONCE]: 'All items cannot be selected to be moved at once. Please try again.',
  [LANGUAGE_KEY.MOVE_REORDER_NO_ITEMS_SELECTED]: 'No items selected. Please select at least one item to move/reorder.',
  [LANGUAGE_KEY.MY_BOOKS]: 'My Books',
  [LANGUAGE_KEY.MY_COURSES]: 'My Courses',
  [LANGUAGE_KEY.NEW_CONTENT]: 'New content',
  [LANGUAGE_KEY.NEW_LESSONS]: 'New Lessons',
  [LANGUAGE_KEY.NEW_LESSONS_ARE_AVAILABLE]: 'New Lessons are available!',
  [LANGUAGE_KEY.NEW_LESSONS_AVAILABLE_NOW]: 'New Lessons: Available Now!',
  [LANGUAGE_KEY.NO_ASSIGNMENTS]: 'No assignments',
  [LANGUAGE_KEY.NO_ASSIGNMENTS_STU]: 'No assignments or resources have been added. Items will be visible after the instructor adds them.',
  [LANGUAGE_KEY.NO_DUE_DATE]: 'No due date',
  [LANGUAGE_KEY.NO_ENROLL]: '0 Enrolled',
  [LANGUAGE_KEY.NO_ITEM_TO_DISPLAY]: 'No items to display',
  [LANGUAGE_KEY.NO_SUBMISSIONS]: 'No submissions',
  [LANGUAGE_KEY.NO_SUBMISSIONS_BEFORE_DUE_DATE]: 'No submissions before due date',
  [LANGUAGE_KEY.NOT_AVAILABLE]: 'Not Available',
  [LANGUAGE_KEY.NOT_DUE]: 'Not Due',
  [LANGUAGE_KEY.NOT_GRADED]: 'Not graded',
  [LANGUAGE_KEY.NOT_STARTED]: 'Not started',
  [LANGUAGE_KEY.NOT_VISIBLE]: 'Not Visible',
  [LANGUAGE_KEY.NUMBER_OF_ATTEMPTS]: '# of Attempts',
  [LANGUAGE_KEY.NUMBER_OF_STUDENTS_WITH_ASSIGNMENTS]: 'Number of students with assignments',
  [LANGUAGE_KEY.ONLY_FOLDERS_CAN_BE_DRAGGED_TO_THE_ROOT_LEVEL]: 'Only folders can be dragged to the root level.',
  [LANGUAGE_KEY.OPEN_COURSE_SETUP]: 'Open course setup',
  [LANGUAGE_KEY.OPEN_EBOOK]: 'Open eBook',
  [LANGUAGE_KEY.OR_A_FOLDER]: 'or a <button>folder</button>',
  [LANGUAGE_KEY.OSMOSIS_PILOT_SURVEY_PROMPT]: 'Elsevier would like to make sure the content resources meet your needs. Please let us know your thoughts about the video you just watched by completing {surveyLink}.',
  [LANGUAGE_KEY.OVERVIEW]: 'Overview',
  [LANGUAGE_KEY.PAGE_TITLE]: 'My Course Plan',
  [LANGUAGE_KEY.PASS]: 'Pass',
  [LANGUAGE_KEY.PASS_FAIL]: 'Pass / Fail',
  [LANGUAGE_KEY.PAST_ASSIGNMENTS]: 'Past Assignments',
  [LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS]: 'Past Due Assignments',
  [LANGUAGE_KEY.PERCENT_OF_CLASS_PERFORMED_CORRECTLY]: '% of Class Performed Correctly',
  [LANGUAGE_KEY.PLEASE_NOTE_THAT_UNASSIGNING]: 'Please note that unassigning items will prevent access and visibility for all students',
  [LANGUAGE_KEY.PLEASE_SELECT_AT_LEAST_ONE_CHAPTER_AND_PAGE]: 'Please select at least 1 chapter and page',
  [LANGUAGE_KEY.PRODUCT]: 'Product',
  [LANGUAGE_KEY.PURCHASE_BINDER_READY_VERSION]: 'Purchase Binder-Ready Version',
  [LANGUAGE_KEY.QUESTION_PREVIEW_MODAL_TITLE]: 'Question Preview',
  [LANGUAGE_KEY.QUESTION_PREVIEW_MODAL_TYPE]: 'type',
  [LANGUAGE_KEY.READING_SELECTION]: 'Reading Selection',
  [LANGUAGE_KEY.REMOVE_NO_ITEMS_SELECTED]: 'No items selected. Please select at least one item to remove.',
  [LANGUAGE_KEY.REPORT_CONTENT_ERROR]: 'Report content error',
  [LANGUAGE_KEY.RESET]: 'Reset',
  [LANGUAGE_KEY.RESOURCE_LIBRARY]: 'Resource Library',
  [LANGUAGE_KEY.RESOURCE_TYPE]: 'Resource type',
  [LANGUAGE_KEY.RESULTS_FILTERED]: 'Results will be filtered as you type.',
  [LANGUAGE_KEY.SAVE_AND_COMPLETE]: 'Save and Complete',
  [LANGUAGE_KEY.SAVE_AND_CONTINUE_TO_ADVANCED_SETTINGS]: 'Save and Continue to Advanced Settings',
  [LANGUAGE_KEY.SCORE]: 'Score',
  [LANGUAGE_KEY.SCORED]: 'Scored',
  [LANGUAGE_KEY.SCORES]: 'Scores',
  [LANGUAGE_KEY.SEARCH_BY_KEYWORD_CHAPTER_OR_RESOURCE_TYPE]: 'Search by keyword, chapter or resource type',
  [LANGUAGE_KEY.SEARCH_BY_COURSE_NAME_PRODUCT_OR_ISBN]: 'Search by course name, product or isbn',
  [LANGUAGE_KEY.SEARCH_COURSE_SECTION]: 'Search Courses',
  [LANGUAGE_KEY.SEARCH_RESOURCES]: 'Search',
  [LANGUAGE_KEY.SEARCH_FOR_A_STUDENT]: 'Search for a student',
  [LANGUAGE_KEY.SECTIONS]: 'Sections',
  [LANGUAGE_KEY.SELECT_A_COURSE_TO_COPY]: 'Select a course to copy',
  [LANGUAGE_KEY.SELECT_ALL]: 'Select all',
  [LANGUAGE_KEY.SELECT_EXISTING_COURSE_REQUIRED_ERROR]: 'You do not have any existing courses that contain the same ISBN entitlements as this course',
  [LANGUAGE_KEY.SET_AS_DEAFULT_TAB]: 'Set as default tab',
  [LANGUAGE_KEY.SIM_ACTIONS_TOOLTIP]: 'Individual Simulation action score is based on the best submission attempt score for that Simulation.',
  [LANGUAGE_KEY.SIMULATION]: 'Simulation',
  [LANGUAGE_KEY.SIMULATION_IS_LOADING]: 'Simulation is loading',
  [LANGUAGE_KEY.SKILL]: 'Skill',
  [LANGUAGE_KEY.SKILL_ATTEMPT_RESULT_CORRECT_TAB]: 'Correct',
  [LANGUAGE_KEY.SKILL_ATTEMPT_RESULT_HEADER]: 'Skill Attempt Result',
  [LANGUAGE_KEY.SKILL_ATTEMPT_RESULT_INCORRECT_TAB]: 'Incorrect',
  [LANGUAGE_KEY.START_A_QUIZ]: 'Start a quiz (EAQ)',
  [LANGUAGE_KEY.START_YOUR_COURSE_BUILDING_BY_PROVIDING_A_NAME_FOR_YOUR_FIRST_FOLDER]: 'Start your course building by providing a name for your first folder.',
  [LANGUAGE_KEY.STATUS]: 'Status',
  [LANGUAGE_KEY.STUDENT]: 'Student',
  [LANGUAGE_KEY.STUDENT_ENGAGEMENT]: 'Student Engagement',
  [LANGUAGE_KEY.STUDENT_SCORES_TITLE]: 'Student Scores',
  [LANGUAGE_KEY.STUDENTS_WATCHED_THIS_VIDEO]: 'Students watched this video',
  [LANGUAGE_KEY.SUBMISSION_ATTEMPTS_TITLE]: 'Submission Attempts',
  [LANGUAGE_KEY.SUBMISSION_TIME]: 'Submission time',
  [LANGUAGE_KEY.SYSTEM_REQUIREMENT_NOTE]: '<strong>Please note</strong>&mdash;depending on your connection speed, etc&mdash;simulations can take anywhere between 30 seconds and 2 minutes or more to fully download. For best results, close all other applications besides your browser and close all other tabs within your browser',
  [LANGUAGE_KEY.THE_FOLDER_IS_EMPTY]: 'The folder is empty',
  [LANGUAGE_KEY.THE_PAGE_NUMBERS_OUT_OF_SCOPE_ERROR]: 'The page numbers/page ranges that you enter must be within the given page numbers',
  [LANGUAGE_KEY.THE_PREVIOUS_CHANGES_YOU_MADE_ON_COURSE_PLAN_AND_THE_ASIGNMENTS_WILL_NOT_BE_PRESERVED]: 'The previous changes you made on Course Plan and the assignments will not be preserved.',
  [LANGUAGE_KEY.THIS_COURSE_INCLUDES_THE_FOLLOWING_PRODUCTS]: 'This course includes the following product(s):',
  [LANGUAGE_KEY.THIS_LESSON_HAS_NOT_RELEASE_YET]: 'This Lesson has not been released yet.',
  [LANGUAGE_KEY.THIS_TYPICALLY_MATCHES_YOUR_SYLLABUS_STRUCTURE]: 'This typically matches your syllabus structure. (ex. Week 1)',
  [LANGUAGE_KEY.TIME_SPENT]: 'Time spent',
  [LANGUAGE_KEY.TOAST_SUCCESS_ADD_SYLLABUS]: ' {numberOfSyllabusItem, plural, one {{assignmentTypeAndName} was added to {folderName}} other {{assignmentTypeAndName} were added to {folderName}}}',
  [LANGUAGE_KEY.TOAST_SUCCESS_MOVE_SYLLABUS]: ' {numberOfSyllabusItem, plural, one {{assignmentTypeAndName} was moved to {folderName}} other {{assignmentTypeAndName} were moved to {folderName}}}',
  [LANGUAGE_KEY.TOAST_SUCCESS_UPDATE_ASSIGNMENTS]: ' {numberOfAssignments, plural, one {{assignmentTypeAndName} was successfully updated} other {{assignmentTypeAndName} were successfully updated}}',
  [LANGUAGE_KEY.TOAST_SUCCESS_UPDATE_SYLLABUS]: ' {numberOfSyllabusItem, plural, one {{assignmentTypeAndName} in {folderName} was successfully updated} other {{assignmentTypeAndName} in {folderName} were successfully updated}}',
  [LANGUAGE_KEY.TO_BE_UNASSIGNED]: 'Items to be unassigned:',
  [LANGUAGE_KEY.TOPICS]: 'Topic(s): ',
  [LANGUAGE_KEY.UNABLE_TO_DROP_EXCEED_LIMIT]: 'Unable to drop. This placement would exceed the nested folder limit.',
  [LANGUAGE_KEY.UNABLE_TO_DROP_INTO_ITS_CHILDREN]: 'Unable to drop. A folder cannot be placed within itself.',
  [LANGUAGE_KEY.UNABLE_TO_DROP_ITSELF]: 'Unable to drop. An item cannot be placed into itself.',
  [LANGUAGE_KEY.UNABLE_TO_UNASSIGN_BECAUSE_ALREADY_UNASSIGNED]: 'Unable to unassign item because the assignment is already unassigned.',
  [LANGUAGE_KEY.UNABLE_TO_UNASSIGN_BECAUSE_GRADED_STARTED]: 'Unable to unassign item because the assignment is graded and has already been started by students.',
  [LANGUAGE_KEY.UNASSIGN_NO_ITEMS_SELECTED]: 'No items selected. Please select at least one item to unassign.',
  [LANGUAGE_KEY.UPCOMING_ASSIGNMENTS]: 'Upcoming Assignments',
  [LANGUAGE_KEY.UPCOMING_TAB_MESSAGE]: 'Assignments highlighted in orange are due within the next 7 days.',
  [LANGUAGE_KEY.UPCOMING_LESSONS]: 'Upcoming Lessons',
  [LANGUAGE_KEY.UPCOMING_LESSONS_MODAL_MESSAGE]: 'The following lessons are being finalized and will be released soon. You can still assign these lessons and they will be visible to your students once they are released.',
  [LANGUAGE_KEY.VIEW]: 'View',
  [LANGUAGE_KEY.VIEW_ALL_ATTEMPTS]: 'View all attempts',
  [LANGUAGE_KEY.VIEW_ATTEMPT_RESULTS]: 'View attempt results',
  [LANGUAGE_KEY.VIEW_DETAILS]: 'View details',
  [LANGUAGE_KEY.VIEW_FULLSCREEN]: 'View Fullscreen',
  [LANGUAGE_KEY.VIEW_NEW_LESSONS]: 'View new Lessons',
  [LANGUAGE_KEY.VIEW_SYSTEM_REQUIREMENTS]: 'To view the minimum system requirements and/or to test your system compatibility',
  [LANGUAGE_KEY.VIEW_UPCOMING_LESSONS]: 'View upcoming Lessons',
  [LANGUAGE_KEY.VISIBLE_ON_DATE_AT_TIME]: 'Visible {availableDate} at {availableTime} {clientZoneAbbr}',
  [LANGUAGE_KEY.VISIBLE_UPON_RELEASE]: 'Visible upon release',
  [LANGUAGE_KEY.VIDEO_COMPLETED_RESULTS_MESSAGE]: 'Displayed video completion % shows the greatest amount watched before the assignment due date. If the assignment does not have a due date or all the user\'s views are late then the displayed video completion % is based on the greatest of all views.',
  [LANGUAGE_KEY.VIDEO_COMPLETION]: 'Video completion %',
  [LANGUAGE_KEY.VIDEO_COMPLETION_BASED_ON_LATEST_VIEW]: 'Video completion % based on latest view',
  [LANGUAGE_KEY.ESTIMATED_TIME]: 'est. {estimatedTime}',
  [LANGUAGE_KEY.WANT_TO_PROCEED]: 'Are you sure you want to proceed?',
  [LANGUAGE_KEY.WARNING_MESSAGE_FOR_REMOVE_WHEN_FILTERED]: 'The action will remove only the visible items selected. When filters are active, folders are not removed. Please clear filters to remove selected folders including all items within.',
  [LANGUAGE_KEY.WARNING_MESSAGE_FOR_UNABLE_TO_REMOVE_WHEN_ASSIGNMENT_STARTED]: 'Unable to remove item because assignment has already been started by students.',
  [LANGUAGE_KEY.WARNING_MESSAGE_FOR_UNASSIGN_WHEN_FILTER]: 'The action will unassign only the visible items selected. When filters are active, items that are not visible are not unassigned. Please clear filters to unassign other selected items.',
  [LANGUAGE_KEY.WARNING_MESSAGE_FOR_UNASSIGN_WHEN_THERE_IS_A_SKIPPED_ITEM]: 'If an item was not assigned or is already unassigned, it cannot be unassigned.',
  [LANGUAGE_KEY.WARNING_MESSAGE_FOR_UPCOMING_LESSON]: 'This Lesson is being finalized and will be released soon. You can still assign this Lesson and it will be visible to your students once it is released.',
  [LANGUAGE_KEY.WE_NEED_TO_KNOW_A_LITTLE_MORE_ABOUT_HOW_TO_STRUCTURE_YOUR_COURSE]: 'We need to know a little more about how to structure your course',
  [LANGUAGE_KEY.WELCOME_TO_YOUR_COURSE]: 'Welcome to your course!',
  [LANGUAGE_KEY.WELCOME_USER]: 'Welcome, {userDisplayName}',
  [LANGUAGE_KEY.WOULD_YOU_LIKE_US_TO_AUTOMATICALLY_ADD_CONTENT_TO_YOUR_COURSE]: 'Would you like us to automatically add content to your course?',
  [LANGUAGE_KEY.YOU_CAN_ADD_REMOVE_MOVE_CONTENT_AFTER_YOUR_COURSE_IS_CREATED]: 'You can add, remove, or move content after your course is created.',
  [LANGUAGE_KEY.YOUR_COURSE_PLAN_IS_EMPTY]: 'Your course plan is empty',
  [LANGUAGE_KEY.YOUR_FILTERED_RESULT_IS_EMPTY]: 'Your filtered result is empty',
  [LANGUAGE_KEY.ASSIGNMENT_CONTENT_ID_IS_EMPTY]: 'Assignment content id is empty',
  [LANGUAGE_KEY.ASSIGNMENT_TITLE_IS_EMPTY]: 'Assignment title is empty',
  [LANGUAGE_KEY.INVALID_AVAILABILITY_DATE]: 'Invalid availability date',
  [LANGUAGE_KEY.INVALID_DUE_DATE]: 'Invalid due date',
  [LANGUAGE_KEY.ENTRY_EXCEEDS_LENGTH_LIMIT]: 'Entry exceeds length limit',
  [LANGUAGE_KEY.VALUE_REQUIRED]: 'Value required',
  [LANGUAGE_KEY.COURSE_COPY_START_DATE_TOOLTIP]: 'The course start is the date on which the first assignment is made visible to students',
  [LANGUAGE_KEY.COPY_URL]: 'Copy URL',
  [LANGUAGE_KEY.COPY_DEEP_LINK_INSTRUCTIONS]: 'Copy this link and place it within you LMS as a deep link',
  [LANGUAGE_KEY.COPY_DEEP_COPY_SUCCESS_TOAST]: 'Deep link successfully copied to clipboard',
  [LANGUAGE_KEY.CLOSE]: 'Close',
  [LANGUAGE_KEY.DEEP_LINK_CREATE_WARNING_TOAST_LINE_1]: 'Assignments must be made available to students before deep links can be created.',
  [LANGUAGE_KEY.DEEP_LINK_CREATE_WARNING_TOAST_LINE_2]: 'Please add an availability date and try again.',
  [LANGUAGE_KEY.INVALID_URL_FORMAT]: 'Invalid URL format',
  [LANGUAGE_KEY.INVALID_URL_PROTOCOL]: 'Invalid URL protocol. The URL must use https',
  [LANGUAGE_KEY.AUTHESS_MODAL_MESSAGE]: 'Some of your assignment statuses were updated in the background.  Please confirm to refresh you assignments.',
  [LANGUAGE_KEY.OKAY]: 'Okay',
  [LANGUAGE_KEY.TRANSCRIPT]: 'Transcript',
  [LANGUAGE_KEY.TOGGLE_TRANSCRIPT]: 'Toggle transcript',
  [LANGUAGE_KEY.GRADE_POINTS]: 'Grade points',
  [LANGUAGE_KEY.INCLUDES]: 'Includes: {includes}',
  [LANGUAGE_KEY.EXTERNAL_LINK_TOOLTIP]: 'Allows you to add external links to resources like course syllabi, lecture recordings, YouTube videos, etc. Links to Elsevier eBooks, courses and other content are not supported.',
  [LANGUAGE_KEY.EXTERNAL_LINK_URL_NOTE]: 'Please enter the link or website address to your resource. You can type it in or copy and paste it. Uploads and Elsevier-related links are not supported.',
  [LANGUAGE_KEY.LAUNCH_STUDENT_VIEW]: 'Launch Student View',
  [LANGUAGE_KEY.STUDENT_LAUNCH_WARNING]: 'Launching the course as a student allows you to access the application as the student. If you take any assignments as the student, progress will be recorded as if the student had taken the assignment. Please proceed with caution when launching as a student.',
  [LANGUAGE_KEY.ADD_MANAGE_EAB_BANK_AND_ASSESSMENT]: 'Add/Manage Banks and Assessments through Elsevier’s Assessment Builder',
  [LANGUAGE_KEY.ADD_MANAGE_EAB_TEST_BANK_AND_ASSESSMENT]: 'Add/Manage Test Banks and Assessments through Elsevier’s Assessment Builder',
  [LANGUAGE_KEY.MASTERY_RESET_PAGE]: 'Mastery Reset Page',
  [LANGUAGE_KEY.RESET_MASTERY]: 'Reset Mastery',
  [LANGUAGE_KEY.ABOUT_MASTERY_RESET]: 'About Mastery Reset',
  [LANGUAGE_KEY.ABOUT_MASTERY_ITEMONE]: 'Once submitted, the mastery level for {lastName}, {firstName} will be reset to zero for the above ISBNs.',
  [LANGUAGE_KEY.ABOUT_MASTERY_ITEMTWO]: 'This student will lose all progress on all assignments in the entire ISBN and will need to start from zero on all assignments.',
  [LANGUAGE_KEY.ABOUT_MASTERY_ITEMTHREE]: 'This reset cannot be restored without assistance from the Elsevier support team.',
  [LANGUAGE_KEY.ABOUT_MASTERY_ITEMFOUR]: 'The reset will continue working in the background even if you navigate to a different page or log out.',
  [LANGUAGE_KEY.ABOUT_MASTERY_ITEMFIVE]: 'This reset request may take up to 10 minutes to complete.',
  [LANGUAGE_KEY.RESET_MASTERY_LEVEL]: 'Reset Mastery Level',
  [LANGUAGE_KEY.MASTERY_RESET_CANCEL]: 'Cancel',
  [LANGUAGE_KEY.MASTERY_RESET_MODAL_HEADING]: 'Mastery Reset for {lastName}, {firstName}',
  [LANGUAGE_KEY.MASTERY_RESET_TOAST_MESSAGE]: 'Mastery reset has been successfully submitted for {lastName}, {firstName}. This process can take up to 10 minutes to complete. To undo, contact our support team',
  [LANGUAGE_KEY.MASTERY_RESET_EMAIL_ADDRESS]: 'Email Address',
  [LANGUAGE_KEY.MASTERY_RESET_FIRST_NAME]: 'First Name',
  [LANGUAGE_KEY.MASTERY_RESET_LAST_NAME]: 'Last Name',
  [LANGUAGE_KEY.SET_ASSESSMENT_GOAL_FOR_ASSIGNMENT]: 'Set an assessment goal for this assignment',
  [LANGUAGE_KEY.MASTERY]: 'Mastery',
  [LANGUAGE_KEY.CUSTOM]: 'Custom',
  [LANGUAGE_KEY.CUSTOM_BY_QUESTIONS]: 'Custom by questions',
  [LANGUAGE_KEY.CASES_ITEMS_NEXT_GENERATION]: 'Cases/Items for Next Generation NCLEX (NGN)',
  [LANGUAGE_KEY.UNLIMITED_PERSONALIZED_QUESTIONS_WILL_BE_PRESENTED]: 'Unlimited, personalized questions will be presented until students level up in the topic of your choice.',
  [LANGUAGE_KEY.CHOOSE_A_FINITE_NUMBER_OF_QUESTIONS]: 'Choose a finite number of questions in the topic(s) of your choice. These questions will be personalized for students and can help students level up.',
  [LANGUAGE_KEY.CHOOSE_SPECIFIC_QUESTIONS]: 'Choose specific questions to include in your quiz. All students will receive the same hand-selected questions which can help students level up in respective topics.',
  [LANGUAGE_KEY.SET_OF_NGN_ITEMS_ALIGNED_TO_CLINICAL_CONTENT]: 'A set of NGN items aligned to clinical content in a chapter to help students apply clinical judgement.',
  [LANGUAGE_KEY.WHAT_WOULD_YOU_LIKE_TO_COVER]: 'What would you like to cover?',
  [LANGUAGE_KEY.SELECT_QUESTIONS]: 'Select Questions',
  [LANGUAGE_KEY.QUESTION_SET]: 'Question set',
  [LANGUAGE_KEY.TOPIC_ORGANIZATION]: 'Topic organization',
  [LANGUAGE_KEY.SELECT_A_QUESTION_SET]: 'Select a question set',
  [LANGUAGE_KEY.SELECT_A_TOPIC_ORGANIZATION]: 'Select a topic organization',
  [LANGUAGE_KEY.PLEASE_SELECT_AN_OPTION]: 'Please select an option',
  [LANGUAGE_KEY.SELECT_AN_OPTION]: 'Select an option',
  [LANGUAGE_KEY.NO_SELECTION]: 'No selection',
  [LANGUAGE_KEY.EXPAND_ALL]: 'Expand All',
  [LANGUAGE_KEY.COLLAPSE_ALL]: 'Collapse All',
  [LANGUAGE_KEY.NUMBER_OF_QUESTIONS]: 'Number of Questions',
  [LANGUAGE_KEY.SELECT_ONE_TOPIC]: 'Select one topic',
  [LANGUAGE_KEY.QUICK_VIEW]: 'Quick View',
  [LANGUAGE_KEY.QUIZ_OPTIONS]: 'Quiz options',
  [LANGUAGE_KEY.QUESTION]: 'Question',
  [LANGUAGE_KEY.RATIONALE]: 'Rationale',
  [LANGUAGE_KEY.ALL_RATIONALES]: 'All Rationales',
  [LANGUAGE_KEY.STUDY_MODE_RATIONALES_MSG1]: 'Students can choose to see rationale <strong>after answering each question</strong>.',
  [LANGUAGE_KEY.STUDY_MODE_RATIONALES_MSG2]: 'Rationales are always shown at the end of the quiz regardless of the mode selected.',
  [LANGUAGE_KEY.EXAM_MODE_RATIONALES_MSG]: 'Student will only see rationale <strong>at the end of the quiz</strong>.',
  [LANGUAGE_KEY.ZERO_TOTAL_TOPICS_SELECTED]: '0 total topics selected',
  [LANGUAGE_KEY.NUMBER_OF_SELECTED]: '{numSelected} of {total} items selected',
  [LANGUAGE_KEY.FIND_QUESTIONS_BY_KEYWORDS]: 'Find questions by keywords',
  [LANGUAGE_KEY.QUESTION_PREVIEW]: 'Question Preview',
  [LANGUAGE_KEY.REMOVE]: 'Remove',
  [LANGUAGE_KEY.PREVIEW]: 'Preview',
  [LANGUAGE_KEY.NO_QUESTION_RESULTS]: 'No Question Results',
  [LANGUAGE_KEY.PLEASE_SELECT_AT_LEAST_1_TOPIC]: 'Please select at least 1 topic',
  [LANGUAGE_KEY.QUESTIONS_ADDED_COVER]: 'Questions added cover',
  [LANGUAGE_KEY.QUESTIONS]: 'Questions',
  [LANGUAGE_KEY.NUM_TOPICS_SELECTED]: '{numSelected} topics selected',
  [LANGUAGE_KEY.SHOWING_NUM_OF_NUM_QUESTIONS]: 'Showing {num} of {total} questions',
  [LANGUAGE_KEY.REVIEW_NUM_QUESTIONS_SELECTED]: 'Review {num} Questions Selected',
  [LANGUAGE_KEY.REVIEW_NUM_QUESTION_SELECTED]: 'Review {num} Question Selected',
  [LANGUAGE_KEY.FETCH_NEXT_100_RESULTS]: 'Fetch next 100 results',
  [LANGUAGE_KEY.SWITCHING_TO_ANOTHER_QUESTION_MSG]: 'Switching to another question set will remove questions already added.',
  [LANGUAGE_KEY.SELECT_MASTERY_GOAL]: 'Select Mastery Goal',
  [LANGUAGE_KEY.NOVICE]: 'Novice',
  [LANGUAGE_KEY.INTERMEDIATE]: 'Intermediate',
  [LANGUAGE_KEY.PROFICIENT]: 'Proficient',
  [LANGUAGE_KEY.GOAL]: 'goal',
  [LANGUAGE_KEY.GOAL_HEADER]: 'Goal',
  [LANGUAGE_KEY.SELECT_MASTERY_GOAL_RADIO]: 'SELECT_MASTERY_GOAL_RADIO',
  [LANGUAGE_KEY.HESI_LOGO_ALT]: 'Hesi logo indicating that the chapter is a HESI Focus Chapter',
  [LANGUAGE_KEY.NUMBER_OF_STUDENTS]: '# of Students',
  [LANGUAGE_KEY.QUESTIONS_ANSWERED]: 'Questions <br />Answered',
  [LANGUAGE_KEY.PRE_NOVICE]: 'Pre-novice',
  [LANGUAGE_KEY.ELSEVIER_ADAPTIVE_QUIZZING]: 'Elsevier Adaptive Quizzing',
  [LANGUAGE_KEY.STUDENT_NAME]: 'Student Name',
  [LANGUAGE_KEY.DATE_SUBMITTED]: 'Date Submitted',
  [LANGUAGE_KEY.CORRECT_ANSWERS]: 'Correct Answers',
  [LANGUAGE_KEY.INCORRECT_ANSWERS]: 'Incorrect Answers',
  [LANGUAGE_KEY.EAQ_BLANK_ASSIGNMENT]: 'EAQ {assignmentType} Assignment',
  [LANGUAGE_KEY.NO_STUDENTS_SELECTED]: 'No students have been assigned to this assignment',
  [LANGUAGE_KEY.FETCH_LAST_NUM_RESULTS]: 'Fetch last {num} results',
  [LANGUAGE_KEY.PLEASE_TRY_ANOTHER_KEYWORD]: 'Something went wrong... Please try another keyword.',
  [LANGUAGE_KEY.E_G_NAME]: 'e.g. John Smith',
  [LANGUAGE_KEY.SEARCH_FOR_A_STUDENT]: 'Search for a student',
  [LANGUAGE_KEY.INTERACTIVE_REVIEW_QUESTIONS_PREVIEW]: 'Interactive Review Questions Preview',
  [LANGUAGE_KEY.IMPORT_EXISTING_QUIZ]: 'Import Existing Quiz',
  [LANGUAGE_KEY.SELECT_A_CUSTOM_BY_QUESTIONS_QUIZ]: 'Select a Custom by Questions quiz already created in this course',
  [LANGUAGE_KEY.NUM_QUESTIONS_IMPORTED]: '{num} questions imported',
  [LANGUAGE_KEY.INCLUDES_QUESTIONS_IMPORTED_FROM]: 'Includes questions imported from: {quiz}',
  [LANGUAGE_KEY.REMOVE_QUESTIONS_SELECTED]: 'Remove all questions',
  [LANGUAGE_KEY.REMOVE_QUESTIONS_SELECTED_MSG]: 'Are you sure you want to remove all selected questions?'
};

export default en;
