import {
  Action,
  CaliperEntityType,
  CaliperEventType,
  Role,
  SoftwareApplicationType
} from '../eols-caliper-commons/eols-caliper-commons.dtos';

export interface AssignmentGoalDto {
  goal: number;
  id: number;
  text: string;
  vtwId: string;
  type?: unknown;
  optional?: unknown;
}

export interface AssignmentTopicDto {
  id?: number;
  text: string;
  vtwId: string;
}

export enum AssessmentStatusDto {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface StudentAssignmentDto {
  createdAt: string;
  id: number;
  status: AssessmentStatusDto;
  updatedAt: string;
  userId: number;
  assessmentId?: number;
  assignmentId?: number;
}

export enum AssignmentGradeType {
  NOT_GRADED = 'NOT_GRADED',
  PASS_FAIL = 'PASS_FAIL',
  SCORED = 'SCORED'
}

export enum AssignmentTargetType {
  COURSE = 'COURSE',
  STUDENT = 'STUDENT'
}

export enum AssignmentType {
  ADAPTIVE_LESSON = 'ADAPTIVE_LESSON',
  COACH = 'COACH',
  EBOOK_READING = 'EBOOK_READING',
  EXAM = 'EXAM',
  LESSONS = 'LESSONS',
  MASTERY = 'MASTERY',
  MINI = 'MINI',
  NON_ADAPTIVE_QUIZZING = 'NON_ADAPTIVE_QUIZZING',
  POST = 'POST',
  QUIZ_BY_QUESTION = 'QUIZ_BY_QUESTION',
  SIMULATIONS = 'SIMULATION',
  SINGLE_STUDENT = 'SINGLE_STUDENT',
  SKILL = 'SKILL',
  STANDARD = 'STANDARD',
  CHART = 'CHART',
  CASE_STUDY = 'CASE_STUDY',
  AUTHESS = 'AUTHESS',
  POWERPOINT = 'POWERPOINT',
  EVOLVE_RESOURCE = 'EVOLVE_RESOURCE',
  SHADOW_HEALTH = 'SHADOW_HEALTH',
  PERSONAL_ASSESSMENT_BUILDER = 'PERSONAL_ASSESSMENT_BUILDER',
  SHERPATH_POWERPOINT = 'SHERPATH_POWERPOINT',
  SHERPATH_GROUP_ACTIVITY = 'SHERPATH_GROUP_ACTIVITY',
  SHERPATH_CASE_STUDY = 'SHERPATH_CASE_STUDY',
  CUSTOM_LINK = 'CUSTOM_LINK',
  OSMOSIS_VIDEO = 'OSMOSIS_VIDEO',
  INTERACTIVE_REVIEW = 'INTERACTIVE_REVIEW'
}

export enum AssignmentScoreType {
  BEST = 'BEST',
  FIRST = 'FIRST',
  LAST = 'LAST'
}

export enum AssignmentOptions {
  all = 'all',
  incorrect = 'incorrect',
  neverAnswered = 'neverAnswered',
  unsure = 'unsure',
  unsureOrIncorrect = 'unsureOrIncorrect'
}

export type AssignmentQuestionDto = {
  id?: string;
  questionVtwId: string;
  displayOrder: number;
}

export interface AssignmentDto {
  assignmentGoals: AssignmentGoalDto[];
  assignmentGradeType: AssignmentGradeType;
  assignmentOptions: [];
  assignmentQuestions: AssignmentQuestionDto[];
  assignmentScoreType: AssignmentScoreType;
  assignmentTopics: AssignmentTopicDto[];
  assignmentType: AssignmentType;
  availableDate: string;
  courseSectionId: number;
  createdAt: string;
  dueDate: string;
  elsAssignmentId: string;
  endDate: string;
  gradePoints?: number;
  id: number;
  isbn: string;
  lmsAssignmentGuid: string;
  sendResultToGradebook: boolean;
  studentAssignments: StudentAssignmentDto[];
  students: number[];
  studyMode: boolean;
  targetType: AssignmentTargetType;
  title: string;
  subtitle: string;
  updatedAt: string;
  userId: number;
  contentId: string;
}

export interface AssessmentGoalDto {
  goal: number;
  text: string;
  vtwId: string;
}

export interface AssessmentTopicLevelUpDto {
  previousLevel: number;
  newLevel: number;
}

export interface AssessmentTopicDto {
  assessmentTopicLevelUps: AssessmentTopicLevelUpDto;
  text: string;
  vtwId: string;
}

export enum AssessmentTypeDto {
  COACH = 'COACH',
  SKILL = 'SKILL',
  POST = 'POST',
  EXAM = 'EXAM',
  MINI = 'MINI',
  MASTERY = 'MASTERY',
  STANDARD = 'STANDARD', // standard book quiz
  QUIZ_BY_QUESTION = 'QUIZ_BY_QUESTION',
  SINGLE_STUDENT = 'SINGLE_STUDENT',
  ADAPTIVE_QUIZ = 'ADAPTIVE_QUIZ',
  NON_ADAPTIVE_QUIZZING = 'NON_ADAPTIVE_QUIZZING',
  SIMULATION = 'SIMULATION',
  LESSONS = 'LESSONS',
  CHART = 'CHART',
  AUTHESS = 'AUTHESS',
  CASE_STUDY = 'CASE_STUDY',
  POWERPOINT = 'POWERPOINT',
  ADAPTIVE_LESSON = 'ADAPTIVE_LESSON',
  CONCEPT_CARD = 'CONCEPT_CARD',
  KNOWLEDGE_CHECK = 'KNOWLEDGE_CHECK',
  PRACTICE_ACTIVITY = 'PRACTICE_ACTIVITY',
  LESSON_QUIZ = 'LESSON_QUIZ',
}

export interface AssessmentDto {
  id?: number;
  assignmentId: number;
  assessmentGoals: AssessmentGoalDto[];
  assessmentTopics: AssessmentTopicDto[];
  studentAssignment: StudentAssignmentDto;
  userId: number;
  courseSectionId: number;
  type: AssessmentTypeDto;
  lessonId: string;
  studyMode: boolean;
  isbn: string;
  contentId: string;
  status: AssessmentStatusDto;
}

export interface AssessmentSubmissionDto {
  id?: number;
  assessmentId?: number;
  userId: number;
  score: number;
  totalCorrect: number;
  totalAnswered: number;
  responseTime: string;
  offsetSeconds: number;
  createdAt: string;
  updatedAt: string;
  timeSpent: number;
}

export interface QtiDataDto {
  questionType: string;
  vtwId: string;
  prompt: string;
  topicVtwId: string;
  difficulty: string;
  remediation: unknown;
  textReference: string[];
  chapter: unknown;
  keyTopic: unknown;
  clientNeeds: unknown;
  hesiConcept: unknown;
  cognitiveLevel: unknown;
  nursingProcess: unknown;
  giddensConcept: unknown;
  qsenCompetency: unknown;
  integratedProcess: unknown;
  correctResponse: string[] | string[][];
  feedback: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  responseChoices: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  targetResponseChoices: {};
}

export interface QuestionSubmissionDto {
  progress: null;
  selectedAnswers: string[];
  qtiData: QtiDataDto;
  timeSpent: number;
  correctResponse: unknown;
  masteryProgressByTaxonomy: unknown;
  correct: boolean;
}

export type AssignmentResultsDto = Record<string, AssignmentStudentResultDto[]>

export interface AssignmentStudentResultDto {
  progress: number;
  timeMinutes: number;
  totalAnswered: number;
  completedDateTime: string;
  quizName: string;
  dueDateTime: string;
  percentage: number;
  badges: unknown;
  questions: QuestionSubmissionDto[];
  totalCorrect: number;
  questionsByVtwId: {
    vtwId: string;
    questionsAnswered: number;
    questionsCorrect: number;
    questionVtwIds: string[];
  }[];
  topics: {
    assessmentTopicLevelUps: [];
    text: string;
    vtwId: string;
  }[];
  studentAssignment: {
    id: number;
    assignmentId: unknown;
    assessmentId: unknown;
    status: string;
    userId: number;
    createdAt: string;
    updatedAt: string;
  };
  gradePoints: number;
}

export interface SessionEvent {
  // 'urn:uuid:' + uuid
  id: string;
  // example: LoggedIn
  action: Action;
  // example: SessionEvent
  type: CaliperEventType;
  membership: {
    // example: eols:member:/9784
    id: string;
    // example: Membership
    type: CaliperEntityType;
    // example: Learner, Administrator, Instructor
    roles: Role[];
  };
  // Represents the agent who generated this event
  actor: {
    // example: eols:users:/9784
    id: string;
    // example: Person
    type: CaliperEntityType;
  };
  // Represents the Elsevier Software Application this event was generated within
  object: {
    // example: sherpath
    id: SoftwareApplicationType;
    // example: SoftwareApplication
    type: CaliperEntityType;
  };
  // A formal collection of people. Examples would include a CourseSection or a Group
  group: {
    // example: eols:courseSection:/2468
    id: string;
    // example: CourseSection
    type: CaliperEntityType;
  };
  // ISO format
  eventTime: string;
  // A map of additional attributes not defined by Caliper
  extension: {
    // The type of content assigned. Examples include: EAQ, Skill, Lesson
    contentType: string;
  };
}
