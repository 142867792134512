import { ELSStepperItem } from '@els/els-component-shared-ts-react';
import {
  AssessmentStatusDto,
  AssignmentGradeType,
  AssignmentType
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export interface StudentPerformance {
  masteryLevel: number;
  questionsAnswered: number;
  questionsCorrect: number;
  questionsConfident: number;
  timeSpent: number;
}

export const EAQ_ASSIGNMENT_EDITOR_PAGE_TITLE = 'Elsevier Adaptive Quizzing - Assignment Management';

export const DEFAULT_EAQ_GRADE_POINTS = 100;

export enum EaqStep {
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
  STEP3 = 'STEP3',
}

export const EaqAssignmentTypeDisplayMap: Partial<Record<AssignmentType, string>> = {
  [AssignmentType.MASTERY]: 'Mastery',
  [AssignmentType.QUIZ_BY_QUESTION]: 'Custom by questions',
  [AssignmentType.AUTHESS]: 'Cases/Items for Next Generation NCLEX (NGN)',
  [AssignmentType.STANDARD]: 'Custom'
};

export const AssignmentGradeTypeDisplayMap: Record<AssignmentGradeType, string> = {
  [AssignmentGradeType.NOT_GRADED]: 'Not Graded',
  [AssignmentGradeType.PASS_FAIL]: 'Pass/Fail',
  [AssignmentGradeType.SCORED]: 'Scored'
};

export const AssignmentGradeDisplayMap: Record<number, string> = {
  0: '',
  1: 'Novice',
  2: 'Intermediate',
  3: 'Proficient'
};

export const AssignmentScoreDisplayMap: Record<AssessmentStatusDto, string> = {
  [AssessmentStatusDto.NOT_STARTED]: 'Not Started',
  [AssessmentStatusDto.IN_PROGRESS]: 'In Progress',
  [AssessmentStatusDto.COMPLETED]: 'Completed'
};

export const EaqAssignmentType = [
  AssignmentType.STANDARD,
  AssignmentType.AUTHESS,
  AssignmentType.MASTERY,
  AssignmentType.QUIZ_BY_QUESTION
];

export const EaqDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: EaqStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: AssignmentType.MASTERY,
    title: 'Step 1'
  }, {
    heading: 'Assignment',
    id: EaqStep.STEP2,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'No topics selected',
    title: 'Step 2'
  }, {
    heading: 'Assignment',
    id: EaqStep.STEP3,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Configure',
    title: 'Step 3'
  }
];

export const MASTERY_LEVELS_CONFIGS = {
  proficient: {
    key: 'proficient',
    field: 'proficient',
    color: 'extended-purple-9',
    label: 'Proficient',
    scoreRange: '(3.0-3.9)'
  },
  intermediate: {
    key: 'intermediate',
    field: 'intermediate',
    color: 'extended-purple-5',
    label: 'Intermediate',
    scoreRange: '(2.0-2.9)'
  },
  novice: {
    key: 'novice',
    field: 'novice',
    color: 'extended-purple-1',
    label: 'Novice',
    scoreRange: '(1.0-1.9)'
  },
  preNovice: {
    key: 'preNovice',
    field: 'preNovice',
    color: 'n1',
    label: 'Incomplete',
    scoreRange: '(0-0.9)'
  }
};

export const HESI_VALUE = 'hesi-focus-chapter';
