import {
  RecContentItemDto,
  RecContentItemTypeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  getAdaptiveLesson, getEaq,
  getEbookReadingTitle,
  getEvolveResource,
  getInteractiveReview,
  getOsmosisVideo,
  getShadowHealth,
  getSherpathCaseStudy,
  getSherpathGroupActivity,
  getSherpathLesson,
  getSherpathPowerpoint,
  getSherpathSimulation,
  getSherpathSkill,
  getSimchart
} from './catalog.utilities';

export const ALL_OPTION_VALUE = 'ALL';
export const ALL_OPTION_NAME = 'All';

export const SyllabusFolderOptionType = {
  ADD_FOLDER: {
    name: 'New Folder',
    value: 'ADD_NEW_SYLLABUS_FOLDER',
  },
  SELECT_FOLDER: {
    name: '--Select Folder--',
    value: 'SELECT_SYLLABUS_FOLDER'
  }
};

export enum EbookPageRangeSeparators {
  CHUNK_SEPARATOR = ',',
  RANGE_SEPARATOR = '-',
}

export const CONFIRM_BULK_ADD_MODAL_ID = 'CONFIRM_BULK_ADD_MODAL_ID';

export const DEFAULT_PROMOTION_CODE = '20006';

export const RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS = 14;

export const LOCATION_PATHNAME = 'location.pathname';

export enum CatalogPageParam {
  STATIC_RESOURCE_TYPE = 'staticResourceType',
  TARGET_SYLLABUS_ITEM_ID = 'targetSyllabusItemId',
  REF = 'ref'
}

export const SequenceMappedTypes = [
  RecContentItemTypeDto.SHERPATH_LESSON,
  RecContentItemTypeDto.SHERPATH_SIMULATION,
  RecContentItemTypeDto.OSMOSIS_VIDEO,
];

export enum CatalogItemInclude {
  'OSMOSIS_VIDEO' = 'Osmosis Video'
}

type RecContentItemTitleHandlerProps = {
  contentItem: RecContentItemDto;
  catalog: CatalogWithExternalEntitiesDto;
  primaryTaxonomies: PrimaryTaxonomy[];
  maxLength: number;
}

export const RecContentItemTitleHandlerMap: Record<RecContentItemTypeDto, (props: RecContentItemTitleHandlerProps) => string> = {
  [RecContentItemTypeDto.QUESTION]: null,
  [RecContentItemTypeDto.HESI_EXAM]: null,
  [RecContentItemTypeDto.SHERPATH_TESTBANK]: null,
  [RecContentItemTypeDto.SHERPATH_MODULE]: null,
  [RecContentItemTypeDto.EBOOK_READING]: (props: RecContentItemTitleHandlerProps) => {
    return getEbookReadingTitle(props.contentItem, props.primaryTaxonomies);
  },
  [RecContentItemTypeDto.ADAPTIVE_LESSON]: (props: RecContentItemTitleHandlerProps) => {
    const adaptiveLesson = getAdaptiveLesson(props.contentItem, props.catalog);
    if (adaptiveLesson) {
      return adaptiveLesson.entity.attributes.text || adaptiveLesson.entity.attributes.displayName;
    }
    return null;
  },
  [RecContentItemTypeDto.EVOLVE_RESOURCE]: (props: RecContentItemTitleHandlerProps) => {
    const evolveResource = getEvolveResource(props.contentItem, props.catalog);
    if (evolveResource) {
      return evolveResource._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHADOW_HEALTH]: (props: RecContentItemTitleHandlerProps) => {
    const shadowHealth = getShadowHealth(props.contentItem, props.catalog);
    if (shadowHealth) {
      return shadowHealth._parsedData.assignmentTitle;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_SKILL]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathSkill = getSherpathSkill(props.contentItem, props.catalog);
    if (sherpathSkill) {
      return sherpathSkill._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_SIMULATION]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathSimulation = getSherpathSimulation(props.contentItem, props.catalog);
    if (sherpathSimulation) {
      return sherpathSimulation._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_LESSON]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathLesson = getSherpathLesson(props.contentItem, props.catalog);
    if (sherpathLesson) {
      return sherpathLesson._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SIM_CHART]: (props: RecContentItemTitleHandlerProps) => {
    const simChart = getSimchart(props.contentItem, props.catalog);
    if (simChart) {
      return simChart._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_POWERPOINT]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathPowerpoint = getSherpathPowerpoint(props.contentItem, props.catalog);
    if (sherpathPowerpoint) {
      return sherpathPowerpoint._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathGroupActivity = getSherpathGroupActivity(props.contentItem, props.catalog);
    if (sherpathGroupActivity) {
      return sherpathGroupActivity._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_CASE_STUDY]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathCaseStudy = getSherpathCaseStudy(props.contentItem, props.catalog);
    if (sherpathCaseStudy) {
      return sherpathCaseStudy._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.OSMOSIS_VIDEO]: (props: RecContentItemTitleHandlerProps) => {
    const osmosisVideo = getOsmosisVideo(props.contentItem, props.catalog);
    if (osmosisVideo) {
      return osmosisVideo._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.ADAPTIVE_QUIZ]: (props: RecContentItemTitleHandlerProps) => {
    const eaq = getEaq(props.contentItem, props.catalog);
    if (eaq) {
      return eaq._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.REVIEW_QUIZ]: (props: RecContentItemTitleHandlerProps) => {
    const interactiveReview = getInteractiveReview(props.contentItem, props.catalog);
    if (interactiveReview) {
      return interactiveReview._parsedData.title;
    }
    return null;
  },
};
